import { Button } from "@mui/material";
import {
  useBulkCloseTasksMutation,
  useCloseTaskMutation,
} from "../../state/rtk-query/state/tasks";
import { useMobile } from "../../themes";
import { ConfirmDeleteDialog } from "../tables";
import { useSelector } from "react-redux";
import { authSelectors } from "../../state";
import { useEffect } from "react";

type Props =
  | {
      ids: number[];
      onSuccess?: () => void;
      id?: undefined;
      orgId?: undefined;
      isFromPaymo?: undefined;
    }
  | {
      id: number;
      orgId: number;
      isFromPaymo?: boolean;
      ids?: undefined;
      onSuccess?: () => void;
    };

export function CompleteTaskButton({
  id,
  orgId,
  isFromPaymo,
  ids,
  onSuccess,
}: Props) {
  const isMobile = useMobile();
  const [closeTask, { isSuccess: isSuccessClose }] = useCloseTaskMutation();
  const [bulkCloseTasks, { isSuccess: isSuccessBulk }] =
    useBulkCloseTasksMutation();
  const isInternal = useSelector(authSelectors.isInternal);
  const isAdmin = useSelector(authSelectors.isAdmin);
  const canCloseSilently = isAdmin || (isInternal && isFromPaymo);
  const isBulk = ids && !id;

  useEffect(() => {
    if (isSuccessBulk && onSuccess && ids) {
      onSuccess();
    }
  }, [isSuccessBulk, onSuccess, ids]);

  useEffect(() => {
    if (isSuccessClose && onSuccess && id) {
      onSuccess();
    }
  }, [isSuccessClose, onSuccess, id]);

  return (
    <ConfirmDeleteDialog
      handleConfirm={() => {
        if (id && orgId) {
          closeTask({ id, orgId, body: {} });
        } else if (ids?.length) {
          bulkCloseTasks({ body: { ids, silent: true } });
        }
      }}
      buttonText={isBulk ? "Close Tasks" : "Complete Task"}
      buttonColor={isBulk ? "info" : "primary"}
      sx={{
        width: 100,
        textWrap: "nowrap",
        height: isMobile ? "fit-content" : "100%",
        py: isMobile ? 0 : "auto",
      }}
      titleText={`Are you sure you want to close ${
        isBulk ? "these tasks" : "this task"
      }?`}
      subTitleText={`Selecting 'Yes' will mark ${
        isBulk ? "these tasks" : "this task"
      } as complete and close ${
        isBulk ? "them" : "it"
      } out. Selecting 'No' will keep the ${isBulk ? "tasks" : "task"} open.`}
      modalProps={{
        confirmActionButtonText: "Yes",
        skipActionButtonText: "No",
      }}
      additionalActions={
        canCloseSilently && (
          <Button
            onClick={() => {
              if (id && orgId) {
                closeTask({ id, orgId, body: { silent: true } });
              } else if (ids?.length) {
                bulkCloseTasks({ body: { ids, silent: false } });
              }
            }}
          >
            {isBulk ? "Close with Notification" : "Close Silently"}
          </Button>
        )
      }
    />
  );
}
