/**
 * @file this listener should only exist in the TimerBar.tsx file
 */
import { useDispatch, useSelector } from "react-redux";
import { authSelectors, timerActions, timerSelectors } from "../../state";
import {
  useGetTimerQuery,
  useUpdateTimerMutation,
} from "../../state/rtk-query/state/timer";
import { useCallback, useEffect, useMemo, useState } from "react";

import { skipToken } from "@reduxjs/toolkit/dist/query";

import { useActivityTypesList } from "./useLists";
import { differenceInMinutes, differenceInSeconds } from "date-fns";
import { NODE_ENV, VITE_TIMER_RESET_MINUTES } from "../../config";
import { MainPages } from "../../pages";
import { matchPath } from "react-router-dom";
import { useTimerActions } from ".";

export const useTimerListener = () => {
  const dispatch = useDispatch();
  const isInternal = useSelector(authSelectors.isInternal);
  const isConsultant = useSelector(authSelectors.isConsultant);
  const isCustomWorkUser = useSelector(authSelectors.isCustomWorkUser);
  const isIt = useSelector(authSelectors.isIT);
  const isSessionPage = matchPath(
    MainPages(true).session.path,
    location.pathname,
  );

  const { getActivityId } = useActivityTypesList();

  const activity_type_id = getActivityId(
    isConsultant
      ? "tasks"
      : isCustomWorkUser
      ? "custom_work"
      : isIt
      ? "it"
      : isSessionPage
      ? "sessions"
      : undefined,
  );

  const [updateTimer] = useUpdateTimerMutation();
  const { startTimer } = useTimerActions();
  const token = useSelector(authSelectors.authToken);
  const [pageLoadType, setPageLoadType] = useState<
    PerformanceNavigationTiming["type"] | undefined
  >();

  const {
    id: timerId,
    orgId: timerOrgId,
    state: timerState,
    stayOnOrgId,
    cancelledSwitch,
    inferredOrgId,
  } = useSelector(timerSelectors.currentTimer) ?? {};
  const { data, refetch } = useGetTimerQuery(
    isInternal && timerId ? { id: timerId } : skipToken,
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const options = useMemo(
    () => ({
      headers: {
        authorization: `Bearer ${token}`,
      },
      keepalive: true, //https://stackoverflow.com/a/67644011
    }),
    [token],
  );

  const createTimer = useCallback(async () => {
    if (!isInternal || cancelledSwitch) return;

    if (inferredOrgId && startTimer) {
      await startTimer({ org_id: inferredOrgId });
    }
  }, [
    isInternal,
    cancelledSwitch,
    timerId,
    timerOrgId,
    inferredOrgId,
    activity_type_id,
  ]);

  const pathPrefix = "/api/V1/timer";

  const tabSwitched = (_e?: Event, endTime = false) => {
    const org =
      cancelledSwitch && stayOnOrgId
        ? stayOnOrgId
        : inferredOrgId === timerOrgId
        ? timerOrgId
        : undefined;

    if (
      (document.visibilityState === "visible" || endTime) &&
      timerId &&
      org &&
      timerState === "active"
    ) {
      const end = endTime ? new Date().toISOString() : null;
      fetch(pathPrefix + `/${timerId}`, {
        method: "put",
        ...options,
        headers: {
          "Content-Type": "application/json",
          ...options.headers,
        },
        body: JSON.stringify({
          end_time: end,
          org_id: org,
        }),
      });
      if (!endTime) refetch();
    }
  };

  const pageClosed = (_e?: PageTransitionEvent) => {
    tabSwitched(undefined, true);
    const org = cancelledSwitch && stayOnOrgId ? stayOnOrgId : timerOrgId;
    fetch(pathPrefix + `/open-tabs/${org || 0}?tab_count_direction=decrement`, {
      method: "post",
      ...options,
    });
  };

  useEffect(() => {
    if (!isInternal) return;
    // https:stackoverflow.com/a/73062712 This will handle reload/tab close
    window.addEventListener("pagehide", pageClosed);

    const pageType = window.performance.getEntriesByType(
      "navigation",
    )[0] as PerformanceNavigationTiming; // https://stackoverflow.com/a/53307588

    setPageLoadType(pageType.type);
    return () => {
      if (!isInternal) return;
      window.removeEventListener("pagehide", pageClosed);
    };
  }, [timerId]);

  const updateTimerInfo = async () => {
    const org =
      cancelledSwitch && stayOnOrgId ? stayOnOrgId : timerOrgId || data?.org_id;

    if (timerId && org) {
      await updateTimer({
        id: timerId,
        body: {
          end_time: null,
          org_id: org,
          activity_type_id,
        },
      });

      dispatch(timerActions.setTimerOrgIdAction(org));
    } else {
      await createTimer();
    }
  };

  // user clears timer and then navigates to a new org
  useEffect(() => {
    (async () => {
      if (
        timerOrgId !== inferredOrgId &&
        timerState === "stopped" &&
        (data?.end_time || data?.deleted_at || !data)
      ) {
        dispatch(timerActions.resetTimerAction());
        await createTimer();
      }
    })();
  }, [timerState, inferredOrgId, timerOrgId, data]);

  // if one tab is closed but the next is still on the same org
  useEffect(() => {
    if (document.visibilityState === "visible") {
      tabSwitched();
    }
  }, [document.visibilityState]);

  const handleCloseAndReopenOrg = () => {
    if (data && data.end_time && data.id === timerId) {
      // we should clear timer state if the timer was stopped longer than VITE_TIMER_RESET_MINUTES just like BE
      if (
        NODE_ENV === "development"
          ? differenceInSeconds(new Date(), new Date(data.end_time)) >= 10
          : differenceInMinutes(new Date(), new Date(data.end_time)) >=
            VITE_TIMER_RESET_MINUTES
      ) {
        return createTimer();
      } else {
        return updateTimerInfo();
      }
    } else {
      return createTimer();
    }
  };

  // Handle "active" state, "switch" state is handled in TimerBar.tsx
  return useEffect(() => {
    if (
      !isInternal ||
      isCustomWorkUser ||
      (timerState && timerState !== "active")
    ) {
      return;
    }

    (async () => {
      if ((pageLoadType === "navigate" || !timerId) && !cancelledSwitch) {
        await handleCloseAndReopenOrg();
      } else {
        if (pageLoadType === "reload" && timerId === data?.id) {
          await handleCloseAndReopenOrg();
        } else {
          if (timerId && !data) {
            await updateTimerInfo();
          } else {
            // if a user ended up in a bad state
            await createTimer();
          }
        }
      }
    })();
  }, [pageLoadType, timerId, cancelledSwitch, data?.id, inferredOrgId]);
};
