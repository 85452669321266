import {
  Box,
  Button,
  ClickAwayListener,
  FormControlLabel,
  FormGroup,
  IconButton,
  Stack,
  useTheme,
} from "@mui/material";
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { actions, authSelectors } from "../../../../state";
import { SendIcon } from "../../../icons";
import {
  GetTaskApiResponse,
  GetTaskChatsApiResponse,
} from "../../../../state/rtk-query/state/tasks";
import { ArrayElement } from "../../../../global";
import { CustomCheckBox } from "../../../styled";
import {
  useAdmireUsersList,
  useCustomEditor,
  useOrgId,
  useOrgsList,
  useOrgUsersList,
  useStatusesList,
} from "../../../hooks";
import { CustomEditorBody, CustomEditorHeader } from "../../../inputs";
import { InlineAttachmentsList } from "../../../misc";
import { useMobile } from "../../../../themes";
import { useDraftChats } from "../../../hooks/useDraftChats";
const {
  useCreateTaskChatMutation,
  useUpdateTaskChatMutation,
  useSaveMediaMutation,
  useCreateTaskMediaMutation,
} = actions;

interface Prop {
  chat?: ArrayElement<GetTaskChatsApiResponse["rows"]> | undefined;
  closeUpdate?: () => void;
  showFullEditor?: boolean;
  createChat?: ReturnType<typeof useCreateTaskChatMutation>[0];
  taskData?: GetTaskApiResponse;
  taskId: number;
}
export const ChatEditor = ({
  chat,
  closeUpdate,
  showFullEditor = false,
  createChat,
  taskData,
  taskId,
}: Prop) => {
  const {
    action_pending: currentActionPending,
    status,
    deleted_at,
    task_watchers,
    type,
  } = taskData || {};
  const { isCompleteStatus } = useStatusesList();
  const disableCommenting =
    (isCompleteStatus(status?.id) && !!chat?.id) || !!deleted_at;
  const {
    attachments,
    isInternalComment,
    message,
    setAttachments,
    setMessage,
    setIsInternalComment,
  } = useDraftChats({ task: taskData, chat });
  const isInternalTask = type === "internal_task";
  const { palette } = useTheme();
  const isMobile = useMobile();
  const isInternal = useSelector(authSelectors.isInternal);
  const orgId = useOrgId(taskData?.org_id);
  const [editorSectionFocus, setEditorSectionFocus] = useState(false);

  const initialAssignToOther =
    ((isInternal && currentActionPending !== "client") ||
      (!isInternal && currentActionPending !== "admire")) &&
    !isInternalTask &&
    !chat?.id &&
    !disableCommenting;
  const [assignToOther, setAssignToOther] = useState(true);

  const [saveMedia] = useSaveMediaMutation();
  const [updateChat] = useUpdateTaskChatMutation();

  const [createTaskMedia] = useCreateTaskMediaMutation();

  const [submitting, setSubmitting] = useState(false);

  const { getOrg } = useOrgsList();
  const org = getOrg(orgId);
  const { admireUsers } = useAdmireUsersList();
  const { orgUsers } = useOrgUsersList(orgId, !orgId || isInternalComment);
  const mentionables = useMemo(() => {
    const twIds = new Set((task_watchers || []).map((w) => w.user_id));
    return [
      ...admireUsers.filter(
        (u) =>
          isInternal ||
          twIds.has(u.id) ||
          u.id === org?.consultant_id ||
          u.id === org?.sub_consultant_id,
      ),
      ...orgUsers,
    ].filter((u) => !u.deleted_at);
  }, [
    task_watchers,
    orgUsers,
    admireUsers,
    isInternal,
    org?.consultant_id,
    org?.sub_consultant_id,
  ]);

  const markAsInternal = useCallback(
    (_e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setIsInternalComment(() => checked || isInternalTask);
    },
    [isInternalComment, taskId],
  );

  const editorOnBlur = useCallback(
    ({ editor }: any) => {
      const msg = editor?.isEmpty ? "" : editor?.getHTML();
      setMessage(msg);
    },
    [setMessage],
  );

  const placeholder = disableCommenting ? "Comments are disabled" : "Comment";
  const editor = useCustomEditor({
    placeholder,
    suggestions: mentionables,
    defaultText: message || "",
    dependencies: [mentionables, editorOnBlur, setAttachments],
    setAttachments,
    onBlur: editorOnBlur,
  });

  const sendMsg = async () => {
    setSubmitting(true);
    const msg = (editor && !editor?.isEmpty && editor.getHTML()) || "";
    editor?.commands.setContent("");
    setMessage("");
    if ((msg && msg.trim() !== "") || attachments.length) {
      if (chat && chat?.id) {
        await updateChat({
          id: chat.id,
          orgId,
          body: {
            message: msg.trim(),
            is_internal: chat?.is_internal || isInternalComment || false, //maybe default to true or update should be able to change value
          },
        });
        if (closeUpdate) {
          closeUpdate();
        }
      } else {
        if (orgId && createChat) {
          const createdChat = await createChat({
            id: taskId,
            orgId,
            body: {
              message: msg.trim(),
              is_internal: isInternalTask || isInternalComment || false,
              has_media: attachments.length > 0,
              ...(isInternal && { no_toggle: !assignToOther }),
            },
          });

          if ("data" in createdChat && attachments.length > 0) {
            [...attachments].map(async (file: any) => {
              const { name } = file ?? {};
              const formData = new FormData();
              formData.append("file", file);

              const missingLabel = name.split(".")[0];
              const retFile = await saveMedia({
                body: { file: formData }, //! is this supposed to be a blob?
                label: missingLabel,
                name: name || missingLabel,
              }).unwrap();

              if (taskId && retFile.id && createdChat) {
                await createTaskMedia({
                  id: taskId,
                  orgId,
                  body: {
                    media_id: retFile.id,
                    chat_id: createdChat.data?.id,
                    is_internal: false,
                  },
                });
              }
              return;
            });
            setAttachments([]);
          }
          setEditorSectionFocus(false);
        }
      }
    }
    setSubmitting(false);
  };

  useEffect(() => {
    if (editor) {
      if (disableCommenting) {
        editor.setOptions({ editable: false });
      } else {
        editor.setOptions({ editable: true });
      }
    }
  }, [editor, disableCommenting]);

  useEffect(() => {
    if (editor?.isFocused) {
      setEditorSectionFocus(true);
    }
  }, [editor?.isFocused]);
  const onClickAway = useCallback(() => {
    if (!editor?.isFocused) {
      setEditorSectionFocus(false);
    }
  }, [editor?.isFocused]);

  const showEditor = (!submitting && editorSectionFocus) || showFullEditor;

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <Stack
        direction={"column"}
        mt={1}
        mb={2}
        sx={{ border: `1px solid ${palette.grey["500"]}`, borderRadius: "4px" }}
      >
        {showEditor && (
          <CustomEditorHeader
            editor={editor}
            showUpload={{ taskId, setAttachments }}
          />
        )}
        <CustomEditorBody
          editor={editor}
          placeholder={placeholder}
          isComplete={disableCommenting}
          editorSectionFocus={showEditor}
        />
        <InlineAttachmentsList
          attachments={attachments}
          setAttachments={setAttachments}
        />
        {showEditor && (
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            sx={{
              backgroundColor: palette.grey[200],
              px: 2,
            }}
          >
            <Stack
              direction={isMobile ? "column" : "row"}
              alignItems={isMobile ? "flex-start" : "center"}
            >
              {isInternal && !isInternalTask && (
                <FormGroup>
                  <FormControlLabel
                    control={
                      <CustomCheckBox
                        onChange={markAsInternal}
                        checked={isInternalComment}
                        props={{ disabled: disableCommenting }}
                      />
                    }
                    disabled={disableCommenting}
                    label="Internal"
                  />
                </FormGroup>
              )}
              {initialAssignToOther && isInternal && (
                <FormControlLabel
                  control={
                    <CustomCheckBox
                      onChange={(_, c) => setAssignToOther(c)}
                      checked={assignToOther && !isInternalComment}
                    />
                  }
                  disabled={isInternalComment}
                  label={`Assign task to ${isInternal ? "client" : "Admire"}`}
                />
              )}
            </Stack>
            <Box>
              {chat && <Button onClick={closeUpdate}>Cancel</Button>}
              <IconButton
                color="primary"
                onClick={sendMsg}
                disabled={
                  submitting || (editor?.isEmpty && !attachments.length)
                }
              >
                <SendIcon fontSize="small" />
              </IconButton>
            </Box>
          </Stack>
        )}
      </Stack>
    </ClickAwayListener>
  );
};
