import { api } from "../api";
export const addTagTypes = [
  "google",
  "calendar",
  "users",
  "reset_password",
  "multi_factor_auth",
] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getGoogleOauthRedirect: build.query<
        GetGoogleOauthRedirectApiResponse,
        GetGoogleOauthRedirectApiArg
      >({
        query: () => ({ url: `/google/oauth-redirect` }),
        providesTags: ["google"],
      }),
      getGoogleAuthStatus: build.query<
        GetGoogleAuthStatusApiResponse,
        GetGoogleAuthStatusApiArg
      >({
        query: () => ({ url: `/google/oauth-status` }),
        providesTags: ["google", "calendar"],
      }),
      authLogin: build.mutation<AuthLoginApiResponse, AuthLoginApiArg>({
        query: (queryArg) => ({
          url: `/auth/login`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["users"],
      }),
      authRefreshToken: build.query<
        AuthRefreshTokenApiResponse,
        AuthRefreshTokenApiArg
      >({
        query: () => ({ url: `/auth/refresh-token` }),
        providesTags: ["users"],
      }),
      authLogout: build.query<AuthLogoutApiResponse, AuthLogoutApiArg>({
        query: () => ({ url: `/auth/logout` }),
        providesTags: ["users"],
      }),
      authSocialLogin: build.mutation<
        AuthSocialLoginApiResponse,
        AuthSocialLoginApiArg
      >({
        query: (queryArg) => ({
          url: `/auth/social-login`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["users"],
      }),
      authForgotPassword: build.mutation<
        AuthForgotPasswordApiResponse,
        AuthForgotPasswordApiArg
      >({
        query: (queryArg) => ({
          url: `/auth/password/forgot`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["users", "reset_password"],
      }),
      authInviteEmail: build.mutation<
        AuthInviteEmailApiResponse,
        AuthInviteEmailApiArg
      >({
        query: (queryArg) => ({
          url: `/auth/password/invite`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["users", "reset_password"],
      }),
      authResetPassword: build.mutation<
        AuthResetPasswordApiResponse,
        AuthResetPasswordApiArg
      >({
        query: (queryArg) => ({
          url: `/auth/password/reset`,
          method: "PUT",
          body: queryArg.body,
        }),
        invalidatesTags: ["users"],
      }),
      authMultiFactorAuth: build.mutation<
        AuthMultiFactorAuthApiResponse,
        AuthMultiFactorAuthApiArg
      >({
        query: (queryArg) => ({
          url: `/auth/multi-factor-auth`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["multi_factor_auth"],
      }),
      authResendMfaCode: build.query<
        AuthResendMfaCodeApiResponse,
        AuthResendMfaCodeApiArg
      >({
        query: (queryArg) => ({
          url: `/auth/resend-mfa-code`,
          params: { type: queryArg["type"] },
        }),
        providesTags: ["multi_factor_auth"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as actions };
export type GetGoogleOauthRedirectApiResponse =
  /** status 200 Oauth redirect url */ {
    oauthRedirectUrl: string;
  };
export type GetGoogleOauthRedirectApiArg = void;
export type GetGoogleAuthStatusApiResponse =
  /** status 200 Oath Status check */ {
    hasAccessToken: boolean;
  };
export type GetGoogleAuthStatusApiArg = void;
export type AuthLoginApiResponse = /** status 200 jwt permissions token */ {
  token: string;
  refreshToken?: string;
  expiration?: string;
  user?: {
    id: number;
    email: string;
    normalized_email?: string | null;
    first_name: string;
    last_name: string;
    password?: string | null;
    login_attempts?: number;
    last_login?: string | null;
    last_failed_login?: string | null;
    forgot_password_token?: string | null;
    forgot_password_created_at?: string | null;
    mfa_code?: string | null;
    mfa_attempts?: number;
    mfa_attempted_at?: string | null;
    mfa_required?: boolean | null;
    internal_notes?: string | null;
    title?:
      | ("Mr." | "Mrs." | "Rabbi" | "Ms." | "Dr." | "Rebbetzin" | null)
      | null;
    availability?: string | null;
    calendly_link?: string | null;
    timer_is_active?: boolean | null;
    calendly_user_info?: string | null;
    default_org_id?: number | null;
    timezone?: string;
    created_at?: string;
    updated_at?: string | null;
    deleted_at?: string | null;
  } & {
    orgs: ({
      id: number;
      pipedrive_org_id?: number | null;
      name?: string;
      phone?: string | null;
      country_code?: string | null;
      website?: string;
      formatted_address?: string | null;
      address?: string | null;
      address_line_2?: string | null;
      city?: string | null;
      state?: string | null;
      country?: string | null;
      zip?: string | null;
      it_info?: string | null;
      consultant_id?: number | null;
      sub_consultant_id?: number | null;
      account_manager_id?: number | null;
      internal_notes?: string | null;
      license_status_id?: number | null;
      code?: string;
      seq?: number;
      quickbooks_customer_id?: string | null;
      created_at?: string;
      updated_at?: string | null;
      deleted_at?: string | null;
    } & {
      user_roles: {
        id?: number;
        user_id?: number;
        role_id: number;
        is_primary?: boolean;
        org_id?: number | null;
        position_id?: number | null;
        preference_id?: number | null;
        created_at?: string;
        updated_at?: string | null;
        deleted_at?: string | null;
        position_name?: string | null;
        role_name?: string | null;
      }[];
    })[];
    phones: {
      id?: number;
      user_id?: number;
      phone_number: string;
      extension?: string | null;
      is_primary?: boolean;
      type: "mobile" | "office" | "home";
      country_code: string;
      sms?: boolean | null;
      created_at?: string;
      updated_at?: string | null;
      deleted_at?: string | null;
    }[];
    calendly_user_info?: string | null;
    has_password?: boolean | null;
  };
};
export type AuthLoginApiArg = {
  /** user login info */
  body: {
    username: string;
    password: string;
  };
};
export type AuthRefreshTokenApiResponse =
  /** status 200 jwt permissions token */ {
    token: string;
    refreshToken?: string;
    expiration?: string;
    user?: {
      id: number;
      email: string;
      normalized_email?: string | null;
      first_name: string;
      last_name: string;
      password?: string | null;
      login_attempts?: number;
      last_login?: string | null;
      last_failed_login?: string | null;
      forgot_password_token?: string | null;
      forgot_password_created_at?: string | null;
      mfa_code?: string | null;
      mfa_attempts?: number;
      mfa_attempted_at?: string | null;
      mfa_required?: boolean | null;
      internal_notes?: string | null;
      title?:
        | ("Mr." | "Mrs." | "Rabbi" | "Ms." | "Dr." | "Rebbetzin" | null)
        | null;
      availability?: string | null;
      calendly_link?: string | null;
      timer_is_active?: boolean | null;
      calendly_user_info?: string | null;
      default_org_id?: number | null;
      timezone?: string;
      created_at?: string;
      updated_at?: string | null;
      deleted_at?: string | null;
    } & {
      orgs: ({
        id: number;
        pipedrive_org_id?: number | null;
        name?: string;
        phone?: string | null;
        country_code?: string | null;
        website?: string;
        formatted_address?: string | null;
        address?: string | null;
        address_line_2?: string | null;
        city?: string | null;
        state?: string | null;
        country?: string | null;
        zip?: string | null;
        it_info?: string | null;
        consultant_id?: number | null;
        sub_consultant_id?: number | null;
        account_manager_id?: number | null;
        internal_notes?: string | null;
        license_status_id?: number | null;
        code?: string;
        seq?: number;
        quickbooks_customer_id?: string | null;
        created_at?: string;
        updated_at?: string | null;
        deleted_at?: string | null;
      } & {
        user_roles: {
          id?: number;
          user_id?: number;
          role_id: number;
          is_primary?: boolean;
          org_id?: number | null;
          position_id?: number | null;
          preference_id?: number | null;
          created_at?: string;
          updated_at?: string | null;
          deleted_at?: string | null;
          position_name?: string | null;
          role_name?: string | null;
        }[];
      })[];
      phones: {
        id?: number;
        user_id?: number;
        phone_number: string;
        extension?: string | null;
        is_primary?: boolean;
        type: "mobile" | "office" | "home";
        country_code: string;
        sms?: boolean | null;
        created_at?: string;
        updated_at?: string | null;
        deleted_at?: string | null;
      }[];
      calendly_user_info?: string | null;
      has_password?: boolean | null;
    };
  };
export type AuthRefreshTokenApiArg = void;
export type AuthLogoutApiResponse = /** status 200 Status check */ {
  status: string;
};
export type AuthLogoutApiArg = void;
export type AuthSocialLoginApiResponse =
  /** status 200 jwt permissions token */ {
    token: string;
    refreshToken?: string;
    expiration?: string;
    user?: {
      id: number;
      email: string;
      normalized_email?: string | null;
      first_name: string;
      last_name: string;
      password?: string | null;
      login_attempts?: number;
      last_login?: string | null;
      last_failed_login?: string | null;
      forgot_password_token?: string | null;
      forgot_password_created_at?: string | null;
      mfa_code?: string | null;
      mfa_attempts?: number;
      mfa_attempted_at?: string | null;
      mfa_required?: boolean | null;
      internal_notes?: string | null;
      title?:
        | ("Mr." | "Mrs." | "Rabbi" | "Ms." | "Dr." | "Rebbetzin" | null)
        | null;
      availability?: string | null;
      calendly_link?: string | null;
      timer_is_active?: boolean | null;
      calendly_user_info?: string | null;
      default_org_id?: number | null;
      timezone?: string;
      created_at?: string;
      updated_at?: string | null;
      deleted_at?: string | null;
    } & {
      orgs: ({
        id: number;
        pipedrive_org_id?: number | null;
        name?: string;
        phone?: string | null;
        country_code?: string | null;
        website?: string;
        formatted_address?: string | null;
        address?: string | null;
        address_line_2?: string | null;
        city?: string | null;
        state?: string | null;
        country?: string | null;
        zip?: string | null;
        it_info?: string | null;
        consultant_id?: number | null;
        sub_consultant_id?: number | null;
        account_manager_id?: number | null;
        internal_notes?: string | null;
        license_status_id?: number | null;
        code?: string;
        seq?: number;
        quickbooks_customer_id?: string | null;
        created_at?: string;
        updated_at?: string | null;
        deleted_at?: string | null;
      } & {
        user_roles: {
          id?: number;
          user_id?: number;
          role_id: number;
          is_primary?: boolean;
          org_id?: number | null;
          position_id?: number | null;
          preference_id?: number | null;
          created_at?: string;
          updated_at?: string | null;
          deleted_at?: string | null;
          position_name?: string | null;
          role_name?: string | null;
        }[];
      })[];
      phones: {
        id?: number;
        user_id?: number;
        phone_number: string;
        extension?: string | null;
        is_primary?: boolean;
        type: "mobile" | "office" | "home";
        country_code: string;
        sms?: boolean | null;
        created_at?: string;
        updated_at?: string | null;
        deleted_at?: string | null;
      }[];
      calendly_user_info?: string | null;
      has_password?: boolean | null;
    };
  };
export type AuthSocialLoginApiArg = {
  /** user social login info */
  body: {
    sign_in_type: "google" | "microsoft";
    credential: string;
    [key: string]: any;
  };
};
export type AuthForgotPasswordApiResponse = /** status 200 Status check */ {
  status: string;
};
export type AuthForgotPasswordApiArg = {
  /** user forgot password */
  body: {
    email: string;
  };
};
export type AuthInviteEmailApiResponse = /** status 200 Status check */ {
  status: string;
};
export type AuthInviteEmailApiArg = {
  /** user forgot password */
  body: {
    email: string;
  };
};
export type AuthResetPasswordApiResponse = /** status 200 Status check */ {
  status: string;
};
export type AuthResetPasswordApiArg = {
  /** user reset password */
  body: {
    token: string;
    newPassword: string;
  };
};
export type AuthMultiFactorAuthApiResponse =
  /** status 200 jwt permissions token */ {
    token: string;
    refreshToken?: string;
    expiration?: string;
    user?: {
      id: number;
      email: string;
      normalized_email?: string | null;
      first_name: string;
      last_name: string;
      password?: string | null;
      login_attempts?: number;
      last_login?: string | null;
      last_failed_login?: string | null;
      forgot_password_token?: string | null;
      forgot_password_created_at?: string | null;
      mfa_code?: string | null;
      mfa_attempts?: number;
      mfa_attempted_at?: string | null;
      mfa_required?: boolean | null;
      internal_notes?: string | null;
      title?:
        | ("Mr." | "Mrs." | "Rabbi" | "Ms." | "Dr." | "Rebbetzin" | null)
        | null;
      availability?: string | null;
      calendly_link?: string | null;
      timer_is_active?: boolean | null;
      calendly_user_info?: string | null;
      default_org_id?: number | null;
      timezone?: string;
      created_at?: string;
      updated_at?: string | null;
      deleted_at?: string | null;
    } & {
      orgs: ({
        id: number;
        pipedrive_org_id?: number | null;
        name?: string;
        phone?: string | null;
        country_code?: string | null;
        website?: string;
        formatted_address?: string | null;
        address?: string | null;
        address_line_2?: string | null;
        city?: string | null;
        state?: string | null;
        country?: string | null;
        zip?: string | null;
        it_info?: string | null;
        consultant_id?: number | null;
        sub_consultant_id?: number | null;
        account_manager_id?: number | null;
        internal_notes?: string | null;
        license_status_id?: number | null;
        code?: string;
        seq?: number;
        quickbooks_customer_id?: string | null;
        created_at?: string;
        updated_at?: string | null;
        deleted_at?: string | null;
      } & {
        user_roles: {
          id?: number;
          user_id?: number;
          role_id: number;
          is_primary?: boolean;
          org_id?: number | null;
          position_id?: number | null;
          preference_id?: number | null;
          created_at?: string;
          updated_at?: string | null;
          deleted_at?: string | null;
          position_name?: string | null;
          role_name?: string | null;
        }[];
      })[];
      phones: {
        id?: number;
        user_id?: number;
        phone_number: string;
        extension?: string | null;
        is_primary?: boolean;
        type: "mobile" | "office" | "home";
        country_code: string;
        sms?: boolean | null;
        created_at?: string;
        updated_at?: string | null;
        deleted_at?: string | null;
      }[];
      calendly_user_info?: string | null;
      has_password?: boolean | null;
    };
  };
export type AuthMultiFactorAuthApiArg = {
  /** mfa code verify */
  body: {
    mfaCode: string;
    rememberMe: boolean;
  };
};
export type AuthResendMfaCodeApiResponse =
  /** status 204 An empty response */ void;
export type AuthResendMfaCodeApiArg = {
  /** defines the type */
  type?: "email" | "sms";
};
export const {
  useGetGoogleOauthRedirectQuery,
  useLazyGetGoogleOauthRedirectQuery,
  useGetGoogleAuthStatusQuery,
  useLazyGetGoogleAuthStatusQuery,
  useAuthLoginMutation,
  useAuthRefreshTokenQuery,
  useLazyAuthRefreshTokenQuery,
  useAuthLogoutQuery,
  useLazyAuthLogoutQuery,
  useAuthSocialLoginMutation,
  useAuthForgotPasswordMutation,
  useAuthInviteEmailMutation,
  useAuthResetPasswordMutation,
  useAuthMultiFactorAuthMutation,
  useAuthResendMfaCodeQuery,
  useLazyAuthResendMfaCodeQuery,
} = injectedRtkApi;
