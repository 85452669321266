import { api } from "../api";
export const addTagTypes = ["org", "users", "tasks", "sessions"] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getOrgsList: build.query<GetOrgsListApiResponse, GetOrgsListApiArg>({
        query: () => ({ url: `/organizations_list` }),
        providesTags: ["org"],
      }),
      getMyOrgsList: build.query<GetMyOrgsListApiResponse, GetMyOrgsListApiArg>(
        {
          query: () => ({ url: `/organizations_list/mine` }),
          providesTags: ["org"],
        },
      ),
      getMyOrgsOverview: build.query<
        GetMyOrgsOverviewApiResponse,
        GetMyOrgsOverviewApiArg
      >({
        query: (queryArg) => ({
          url: `/organizations/mine/overview`,
          params: {
            sort: queryArg.sort,
            field: queryArg.field,
            filter: queryArg.filter,
            range: queryArg.range,
            linkType: queryArg.linkType,
            q: queryArg.q,
          },
        }),
        providesTags: ["org"],
      }),
      getUsersInOrg: build.query<GetUsersInOrgApiResponse, GetUsersInOrgApiArg>(
        {
          query: (queryArg) => ({
            url: `/organization/mine/users`,
            params: {
              sort: queryArg.sort,
              field: queryArg.field,
              filter: queryArg.filter,
              range: queryArg.range,
              linkType: queryArg.linkType,
              q: queryArg.q,
            },
          }),
          providesTags: ["users", "org"],
        },
      ),
      getOrgs: build.query<GetOrgsApiResponse, GetOrgsApiArg>({
        query: (queryArg) => ({
          url: `/organizations`,
          params: {
            sort: queryArg.sort,
            field: queryArg.field,
            filter: queryArg.filter,
            range: queryArg.range,
            linkType: queryArg.linkType,
            q: queryArg.q,
            inactive: queryArg.inactive,
          },
        }),
        providesTags: ["org"],
      }),
      createOrg: build.mutation<CreateOrgApiResponse, CreateOrgApiArg>({
        query: (queryArg) => ({
          url: `/organizations`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["org"],
      }),
      getOrgByIdInternal: build.query<
        GetOrgByIdInternalApiResponse,
        GetOrgByIdInternalApiArg
      >({
        query: (queryArg) => ({
          url: `/organization/${queryArg.orgId}/internal`,
        }),
        providesTags: ["org"],
      }),
      getOrgById: build.query<GetOrgByIdApiResponse, GetOrgByIdApiArg>({
        query: (queryArg) => ({ url: `/organization/${queryArg.orgId}` }),
        providesTags: ["org"],
      }),
      updateOrg: build.mutation<UpdateOrgApiResponse, UpdateOrgApiArg>({
        query: (queryArg) => ({
          url: `/organization/${queryArg.orgId}`,
          method: "PUT",
          body: queryArg.body,
        }),
        invalidatesTags: ["org"],
      }),
      deleteOrg: build.mutation<DeleteOrgApiResponse, DeleteOrgApiArg>({
        query: (queryArg) => ({
          url: `/organization/${queryArg.orgId}`,
          method: "DELETE",
        }),
        invalidatesTags: ["org"],
      }),
      getOrgUsersById: build.query<
        GetOrgUsersByIdApiResponse,
        GetOrgUsersByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/organization/${queryArg.orgId}/users`,
          params: {
            sort: queryArg.sort,
            field: queryArg.field,
            filter: queryArg.filter,
            range: queryArg.range,
            linkType: queryArg.linkType,
            q: queryArg.q,
          },
        }),
        providesTags: ["org"],
      }),
      addUserToOrg: build.mutation<AddUserToOrgApiResponse, AddUserToOrgApiArg>(
        {
          query: (queryArg) => ({
            url: `/organization/${queryArg.orgId}/users`,
            method: "POST",
            body: queryArg.body,
          }),
          invalidatesTags: ["users", "org"],
        },
      ),
      getOrgUserById: build.query<
        GetOrgUserByIdApiResponse,
        GetOrgUserByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/organization/${queryArg.orgId}/users/${queryArg.id}`,
        }),
        providesTags: ["org"],
      }),
      updateUserInOrg: build.mutation<
        UpdateUserInOrgApiResponse,
        UpdateUserInOrgApiArg
      >({
        query: (queryArg) => ({
          url: `/organization/${queryArg.orgId}/users/${queryArg.id}`,
          method: "PUT",
          body: queryArg.body,
        }),
        invalidatesTags: ["users", "org"],
      }),
      deleteOrgUser: build.mutation<
        DeleteOrgUserApiResponse,
        DeleteOrgUserApiArg
      >({
        query: (queryArg) => ({
          url: `/organization/${queryArg.orgId}/users/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["users", "org"],
      }),
      updateUserProfileInOrg: build.mutation<
        UpdateUserProfileInOrgApiResponse,
        UpdateUserProfileInOrgApiArg
      >({
        query: (queryArg) => ({
          url: `/organization/${queryArg.orgId}/profile/mine`,
          method: "PUT",
          body: queryArg.body,
        }),
        invalidatesTags: ["users", "org"],
      }),
      getOrgLinksById: build.query<
        GetOrgLinksByIdApiResponse,
        GetOrgLinksByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/organization/${queryArg.orgId}/links`,
          params: {
            sort: queryArg.sort,
            field: queryArg.field,
            filter: queryArg.filter,
            range: queryArg.range,
            linkType: queryArg.linkType,
            q: queryArg.q,
          },
        }),
        providesTags: ["org"],
      }),
      getOrgAnnouncements: build.query<
        GetOrgAnnouncementsApiResponse,
        GetOrgAnnouncementsApiArg
      >({
        query: (queryArg) => ({
          url: `/organization/${queryArg.orgId}/announcements`,
        }),
        providesTags: ["org"],
      }),
      getLicensesAndStatuses: build.query<
        GetLicensesAndStatusesApiResponse,
        GetLicensesAndStatusesApiArg
      >({
        query: () => ({ url: `/organization_licenses` }),
        providesTags: ["org"],
      }),
      updateOrgsConsultant: build.mutation<
        UpdateOrgsConsultantApiResponse,
        UpdateOrgsConsultantApiArg
      >({
        query: (queryArg) => ({
          url: `/organizations/consultants`,
          method: "PUT",
          body: queryArg.body,
        }),
        invalidatesTags: ["org"],
      }),
      bulkDeleteOrgsSubConsultant: build.mutation<
        BulkDeleteOrgsSubConsultantApiResponse,
        BulkDeleteOrgsSubConsultantApiArg
      >({
        query: (queryArg) => ({
          url: `/organizations/consultants`,
          method: "DELETE",
          body: queryArg.body,
        }),
        invalidatesTags: ["org"],
      }),
      getOrgTasks: build.query<GetOrgTasksApiResponse, GetOrgTasksApiArg>({
        query: (queryArg) => ({
          url: `/organization/${queryArg.orgId}/tasks`,
          params: {
            assignees: queryArg.assignees,
            awaiting: queryArg.awaiting,
            range: queryArg.range,
            type: queryArg["type"],
            topics: queryArg.topics,
            dateRange: queryArg.dateRange,
            statuses: queryArg.statuses,
          },
        }),
        providesTags: ["org", "tasks"],
      }),
      getOrgActivities: build.query<
        GetOrgActivitiesApiResponse,
        GetOrgActivitiesApiArg
      >({
        query: (queryArg) => ({
          url: `/organization/${queryArg.orgId}/activities`,
        }),
        providesTags: ["org", "tasks", "sessions"],
      }),
      getOrgUsersList: build.query<
        GetOrgUsersListApiResponse,
        GetOrgUsersListApiArg
      >({
        query: (queryArg) => ({
          url: `/organization/${queryArg.orgId}/users_list`,
        }),
        providesTags: ["org"],
      }),
      applyTemplate: build.mutation<
        ApplyTemplateApiResponse,
        ApplyTemplateApiArg
      >({
        query: (queryArg) => ({
          url: `/organization/${queryArg.orgId}/apply-template`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["org", "sessions", "tasks"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as actions };
export type GetOrgsListApiResponse =
  /** status 200 list of entity ids and names */ {
    id: number;
    name: string;
  }[];
export type GetOrgsListApiArg = void;
export type GetMyOrgsListApiResponse =
  /** status 200 list of entity ids and names */ {
    id: number;
    name: string;
  }[];
export type GetMyOrgsListApiArg = void;
export type GetMyOrgsOverviewApiResponse = /** status 200 Org overview */ {
  count: number;
  rows: {
    id: number;
    account_manager_id?: number | null;
    license_status_id?: number | null;
    name: string;
    openTasks?: number;
    scheduledSessions?: number;
    unreadMessages?: number;
    nextSession?: string | null;
    nextSessionStatus?: number | null;
    previousSession?: string | null;
    previousSessionStatus?: number | null;
  }[];
};
export type GetMyOrgsOverviewApiArg = {
  /** order by */
  sort?: string;
  /** filter by */
  field?: string;
  /** filter by */
  filter?: string;
  /** range for query limit */
  range?: number[];
  /** link type */
  linkType?: "and" | "or";
  /** search Query param */
  q?: string;
};
export type GetUsersInOrgApiResponse =
  /** status 200 catch all response response */ {
    count: number;
    rows: ({
      id: number;
      email: string;
      first_name: string;
      last_name: string;
      mfa_required?: boolean | null;
      is_primary?: boolean | null;
      title?:
        | ("Mr." | "Mrs." | "Ms." | "Dr." | "Rabbi" | "Rebbetzin" | null)
        | null;
      internal_notes?: string | null;
      availability?: string | null;
      phones: {
        id?: number;
        user_id?: number;
        phone_number: string;
        country_code: string;
        type: "mobile" | "home" | "office";
        extension?: string | null;
        sms?: boolean | null;
        is_primary?: boolean | null;
        created_at?: string | null;
        updated_at?: string | null;
        deleted_at?: string | null;
      }[];
      created_at?: string | null;
      has_password?: boolean;
      timer_is_active?: boolean | null;
    } & {
      user_roles: ({
        user_id: number;
        role_id: number;
        org_id: number;
        position_id?: number | null;
        is_primary?: boolean | null;
      } & {
        role?: {
          id?: number;
          internal_name: string;
          name: string;
          type?: "internal" | "external";
          created_at?: string | null;
          updated_at?: string | null;
          deleted_at?: string | null;
        };
        /** organizations */
        org?: {
          id: number;
          pipedrive_org_id?: number | null;
          name: string;
          phone?: string;
          country_code?: string | null;
          website?: string;
          address?: string;
          consultant_id?: number | null;
          sub_consultant_id?: number | null;
          account_manager_id?: number | null;
          license_status_id?: number | null;
          it_info?: string | null;
          internal_notes?: string | null;
          code?: string;
          created_at?: string | null;
          updated_at?: string | null;
          deleted_at?: string | null;
          quickbooks_customer_id?: string | null;
        };
      })[];
      calendly_link?: string | null;
      calendly_user_info?: string | null;
      default_org_id?: number | null;
    })[];
  };
export type GetUsersInOrgApiArg = {
  /** order by */
  sort?: string;
  /** filter by */
  field?: string;
  /** filter by */
  filter?: string;
  /** range for query limit */
  range?: number[];
  /** link type */
  linkType?: "and" | "or";
  /** search Query param */
  q?: string;
};
export type GetOrgsApiResponse = /** status 200 Organizations */ {
  count: number;
  rows: ({
    id: number;
    pipedrive_org_id?: number | null;
    name: string;
    phone?: string;
    country_code?: string | null;
    website?: string;
    address?: string;
    consultant_id?: number | null;
    sub_consultant_id?: number | null;
    account_manager_id?: number | null;
    license_status_id?: number | null;
    it_info?: string | null;
    internal_notes?: string | null;
    code?: string;
    created_at?: string | null;
    updated_at?: string | null;
    deleted_at?: string | null;
    quickbooks_customer_id?: string | null;
  } & {
    organization_links: {
      id: number;
      label?: string | null;
      link: string;
      org_id?: number;
      sort?: number | null;
      created_at?: string | null;
      updated_at?: string | null;
      deleted_at?: string | null;
    }[];
    open_tasks?: number;
  })[];
};
export type GetOrgsApiArg = {
  /** order by */
  sort?: string;
  /** filter by */
  field?: string;
  /** filter by */
  filter?: string;
  /** range for query limit */
  range?: number[];
  /** link type */
  linkType?: "and" | "or";
  /** search Query param */
  q?: string;
  /** Get inactive users */
  inactive?: boolean;
};
export type CreateOrgApiResponse = /** status 204 An empty response */ void;
export type CreateOrgApiArg = {
  /** org */
  body: {
    id?: number;
    pipedrive_org_id?: number | null;
    name: string;
    phone?: string;
    country_code?: string | null;
    website?: string;
    address?: string;
    consultant_id?: number | null;
    sub_consultant_id?: number | null;
    account_manager_id?: number | null;
    license_status_id: number | null;
    it_info?: string | null;
    internal_notes?: string | null;
    code: string;
    created_at?: string | null;
    updated_at?: string | null;
    deleted_at?: string | null;
    quickbooks_customer_id?: string | null;
  } & {
    quick_links: {
      id?: number;
      label?: string | null;
      link: string;
      org_id?: number;
      sort?: number | null;
      created_at?: string | null;
      updated_at?: string | null;
      deleted_at?: string | null;
    }[];
  };
};
export type GetOrgByIdInternalApiResponse =
  /** status 200 catch all response response */ ({
    id: number;
    pipedrive_org_id?: number | null;
    name: string;
    phone?: string;
    country_code?: string | null;
    website?: string;
    address?: string;
    consultant_id?: number | null;
    sub_consultant_id?: number | null;
    account_manager_id?: number | null;
    license_status_id?: number | null;
    it_info?: string | null;
    internal_notes?: string | null;
    code?: string;
    created_at?: string | null;
    updated_at?: string | null;
    deleted_at?: string | null;
    quickbooks_customer_id?: string | null;
  } & {
    organization_links: {
      id: number;
      label?: string | null;
      link: string;
      org_id?: number;
      sort?: number | null;
      created_at?: string | null;
      updated_at?: string | null;
      deleted_at?: string | null;
    }[];
    open_tasks?: number;
  }) & {
    consultant?: {
      id: number;
      email: string;
      first_name: string;
      last_name: string;
      mfa_required?: boolean | null;
      is_primary?: boolean | null;
      title?:
        | ("Mr." | "Mrs." | "Ms." | "Dr." | "Rabbi" | "Rebbetzin" | null)
        | null;
      internal_notes?: string | null;
      availability?: string | null;
      phones?: {
        id?: number;
        user_id?: number;
        phone_number: string;
        country_code: string;
        type: "mobile" | "home" | "office";
        extension?: string | null;
        sms?: boolean | null;
        is_primary?: boolean | null;
        created_at?: string | null;
        updated_at?: string | null;
        deleted_at?: string | null;
      }[];
      created_at?: string | null;
      has_password?: boolean;
      timer_is_active?: boolean | null;
    } | null;
    sub_consultant?: {
      id: number;
      email: string;
      first_name: string;
      last_name: string;
      mfa_required?: boolean | null;
      is_primary?: boolean | null;
      title?:
        | ("Mr." | "Mrs." | "Ms." | "Dr." | "Rabbi" | "Rebbetzin" | null)
        | null;
      internal_notes?: string | null;
      availability?: string | null;
      phones?: {
        id?: number;
        user_id?: number;
        phone_number: string;
        country_code: string;
        type: "mobile" | "home" | "office";
        extension?: string | null;
        sms?: boolean | null;
        is_primary?: boolean | null;
        created_at?: string | null;
        updated_at?: string | null;
        deleted_at?: string | null;
      }[];
      created_at?: string | null;
      has_password?: boolean;
      timer_is_active?: boolean | null;
    } | null;
    account_manager?: {
      id: number;
      email: string;
      first_name: string;
      last_name: string;
      mfa_required?: boolean | null;
      is_primary?: boolean | null;
      title?:
        | ("Mr." | "Mrs." | "Ms." | "Dr." | "Rabbi" | "Rebbetzin" | null)
        | null;
      internal_notes?: string | null;
      availability?: string | null;
      phones?: {
        id?: number;
        user_id?: number;
        phone_number: string;
        country_code: string;
        type: "mobile" | "home" | "office";
        extension?: string | null;
        sms?: boolean | null;
        is_primary?: boolean | null;
        created_at?: string | null;
        updated_at?: string | null;
        deleted_at?: string | null;
      }[];
      created_at?: string | null;
      has_password?: boolean;
      timer_is_active?: boolean | null;
    } | null;
    license_status?: ({
      id: number;
      name: string;
      license_id: number;
    } | null) &
      ({
        license?: {
          id: number;
          name: string;
        };
      } | null);
  };
export type GetOrgByIdInternalApiArg = {
  /** organization id */
  orgId: number;
};
export type GetOrgByIdApiResponse = /** status 200 Show single OrgExtended */ {
  id: number;
  pipedrive_org_id: number | null;
  name: string;
  phone: string | null;
  country_code: string | null;
  website: string;
  formatted_address?: string | null;
  address: string | null;
  address_line_2?: string | null;
  city?: string | null;
  state?: string | null;
  country?: string | null;
  zip?: string | null;
  it_info?: string | null;
  consultant_id?: number | null;
  sub_consultant_id?: number | null;
  account_manager_id?: number | null;
  internal_notes?: string | null;
  license_status_id?: number | null;
  code?: string;
  seq?: number;
  quickbooks_customer_id?: string | null;
  created_at?: string;
  updated_at?: string | null;
  deleted_at?: string | null;
  /** users schema */
  account_manager?: {
    id: number;
    email?: string;
    normalized_email?: string | null;
    first_name: string;
    last_name: string;
    password?: string | null;
    login_attempts?: number;
    last_login?: string | null;
    last_failed_login?: string | null;
    forgot_password_token?: string | null;
    forgot_password_created_at?: string | null;
    mfa_code?: string | null;
    mfa_attempts?: number;
    mfa_attempted_at?: string | null;
    mfa_required?: boolean | null;
    internal_notes?: string | null;
    title?:
      | ("Mr." | "Mrs." | "Rabbi" | "Ms." | "Dr." | "Rebbetzin" | null)
      | null;
    availability?: string | null;
    calendly_link?: string | null;
    timer_is_active?: boolean | null;
    calendly_user_info?: string | null;
    default_org_id?: number | null;
    timezone?: string;
    created_at?: string;
    updated_at?: string | null;
    deleted_at?: string | null;
  } | null;
  /** users schema */
  consultant?: {
    id: number;
    email?: string;
    normalized_email?: string | null;
    first_name: string;
    last_name: string;
    password?: string | null;
    login_attempts?: number;
    last_login?: string | null;
    last_failed_login?: string | null;
    forgot_password_token?: string | null;
    forgot_password_created_at?: string | null;
    mfa_code?: string | null;
    mfa_attempts?: number;
    mfa_attempted_at?: string | null;
    mfa_required?: boolean | null;
    internal_notes?: string | null;
    title?:
      | ("Mr." | "Mrs." | "Rabbi" | "Ms." | "Dr." | "Rebbetzin" | null)
      | null;
    availability?: string | null;
    calendly_link?: string | null;
    timer_is_active?: boolean | null;
    calendly_user_info?: string | null;
    default_org_id?: number | null;
    timezone?: string;
    created_at?: string;
    updated_at?: string | null;
    deleted_at?: string | null;
  } | null;
  /** users schema */
  sub_consultant?: {
    id: number;
    email?: string;
    normalized_email?: string | null;
    first_name: string;
    last_name: string;
    password?: string | null;
    login_attempts?: number;
    last_login?: string | null;
    last_failed_login?: string | null;
    forgot_password_token?: string | null;
    forgot_password_created_at?: string | null;
    mfa_code?: string | null;
    mfa_attempts?: number;
    mfa_attempted_at?: string | null;
    mfa_required?: boolean | null;
    internal_notes?: string | null;
    title?:
      | ("Mr." | "Mrs." | "Rabbi" | "Ms." | "Dr." | "Rebbetzin" | null)
      | null;
    availability?: string | null;
    calendly_link?: string | null;
    timer_is_active?: boolean | null;
    calendly_user_info?: string | null;
    default_org_id?: number | null;
    timezone?: string;
    created_at?: string;
    updated_at?: string | null;
    deleted_at?: string | null;
  } | null;
  /** license_statuses schema */
  license_status?: {
    id: number;
    name: string;
    license_id?: number;
    created_at?: string;
    updated_at?: string | null;
    deleted_at?: string | null;
    /** licenses schema */
    license?: {
      id: number;
      name: string;
      created_at?: string;
      updated_at?: string | null;
      deleted_at?: string | null;
    };
  } | null;
  organization_links: {
    id: number;
    label: string | null;
    link: string;
    org_id: number;
    sort: number | null;
    created_at?: string;
    updated_at?: string | null;
    deleted_at?: string | null;
  }[];
};
export type GetOrgByIdApiArg = {
  /** organization id */
  orgId: number;
};
export type UpdateOrgApiResponse = /** status 204 An empty response */ void;
export type UpdateOrgApiArg = {
  /** organization id */
  orgId: number;
  /** org */
  body: {
    id?: number;
    pipedrive_org_id?: number | null;
    name: string;
    phone?: string;
    country_code?: string | null;
    website?: string;
    address?: string;
    consultant_id?: number | null;
    sub_consultant_id?: number | null;
    account_manager_id?: number | null;
    license_status_id: number | null;
    it_info?: string | null;
    internal_notes?: string | null;
    code: string;
    created_at?: string | null;
    updated_at?: string | null;
    deleted_at?: string | null;
    quickbooks_customer_id?: string | null;
  } & {
    quick_links: {
      id?: number;
      label?: string | null;
      link: string;
      org_id?: number;
      sort?: number | null;
      created_at?: string | null;
      updated_at?: string | null;
      deleted_at?: string | null;
    }[];
  };
};
export type DeleteOrgApiResponse = /** status 204 An empty response */ void;
export type DeleteOrgApiArg = {
  /** organization id */
  orgId: number;
};
export type GetOrgUsersByIdApiResponse =
  /** status 200 Multiple user response with orgs and roles */ {
    count: number;
    rows: ({
      id: number;
      email: string;
      normalized_email?: string | null;
      first_name: string;
      last_name: string;
      password?: string | null;
      login_attempts?: number;
      last_login?: string | null;
      last_failed_login?: string | null;
      forgot_password_token?: string | null;
      forgot_password_created_at?: string | null;
      mfa_code?: string | null;
      mfa_attempts?: number;
      mfa_attempted_at?: string | null;
      mfa_required?: boolean | null;
      internal_notes?: string | null;
      title?:
        | ("Mr." | "Mrs." | "Rabbi" | "Ms." | "Dr." | "Rebbetzin" | null)
        | null;
      availability?: string | null;
      calendly_link?: string | null;
      timer_is_active?: boolean | null;
      calendly_user_info?: string | null;
      default_org_id?: number | null;
      timezone?: string;
      created_at?: string;
      updated_at?: string | null;
      deleted_at?: string | null;
    } & {
      orgs: ({
        id: number;
        pipedrive_org_id?: number | null;
        name?: string;
        phone?: string | null;
        country_code?: string | null;
        website?: string;
        formatted_address?: string | null;
        address?: string | null;
        address_line_2?: string | null;
        city?: string | null;
        state?: string | null;
        country?: string | null;
        zip?: string | null;
        it_info?: string | null;
        consultant_id?: number | null;
        sub_consultant_id?: number | null;
        account_manager_id?: number | null;
        internal_notes?: string | null;
        license_status_id?: number | null;
        code?: string;
        seq?: number;
        quickbooks_customer_id?: string | null;
        created_at?: string;
        updated_at?: string | null;
        deleted_at?: string | null;
      } & {
        user_roles: {
          id?: number;
          user_id?: number;
          role_id: number;
          is_primary?: boolean;
          org_id?: number | null;
          position_id?: number | null;
          preference_id?: number | null;
          created_at?: string;
          updated_at?: string | null;
          deleted_at?: string | null;
          position_name?: string | null;
          role_name?: string | null;
        }[];
      })[];
      phones: {
        id?: number;
        user_id?: number;
        phone_number: string;
        extension?: string | null;
        is_primary?: boolean;
        type: "mobile" | "office" | "home";
        country_code: string;
        sms?: boolean | null;
        created_at?: string;
        updated_at?: string | null;
        deleted_at?: string | null;
      }[];
      calendly_user_info?: string | null;
      has_password?: boolean | null;
    })[];
  };
export type GetOrgUsersByIdApiArg = {
  /** order by */
  sort?: string;
  /** filter by */
  field?: string;
  /** filter by */
  filter?: string;
  /** range for query limit */
  range?: number[];
  /** link type */
  linkType?: "and" | "or";
  /** search Query param */
  q?: string;
  /** organization id */
  orgId: number;
};
export type AddUserToOrgApiResponse =
  /** status 204 An empty response */ void | /** status 214 success with warnings */ {
    code: number;
    message: string;
  };
export type AddUserToOrgApiArg = {
  /** organization id */
  orgId: number;
  /** Create single user */
  body: {
    id: number;
    email: string;
    normalized_email?: string | null;
    first_name: string;
    last_name: string;
    password?: string | null;
    login_attempts?: number;
    last_login?: string | null;
    last_failed_login?: string | null;
    forgot_password_token?: string | null;
    forgot_password_created_at?: string | null;
    mfa_code?: string | null;
    mfa_attempts?: number;
    mfa_attempted_at?: string | null;
    mfa_required?: boolean | null;
    internal_notes?: string | null;
    title?:
      | ("Mr." | "Mrs." | "Rabbi" | "Ms." | "Dr." | "Rebbetzin" | null)
      | null;
    availability?: string | null;
    calendly_link?: string | null;
    timer_is_active?: boolean | null;
    calendly_user_info?: string | null;
    default_org_id?: number | null;
    timezone?: string;
    created_at?: string;
    updated_at?: string | null;
    deleted_at?: string | null;
  } & {
    orgs: ({
      id: number;
      pipedrive_org_id?: number | null;
      name?: string;
      phone?: string | null;
      country_code?: string | null;
      website?: string;
      formatted_address?: string | null;
      address?: string | null;
      address_line_2?: string | null;
      city?: string | null;
      state?: string | null;
      country?: string | null;
      zip?: string | null;
      it_info?: string | null;
      consultant_id?: number | null;
      sub_consultant_id?: number | null;
      account_manager_id?: number | null;
      internal_notes?: string | null;
      license_status_id?: number | null;
      code?: string;
      seq?: number;
      quickbooks_customer_id?: string | null;
      created_at?: string;
      updated_at?: string | null;
      deleted_at?: string | null;
    } & {
      user_roles: {
        id?: number;
        user_id?: number;
        role_id: number;
        is_primary?: boolean;
        org_id?: number | null;
        position_id?: number | null;
        preference_id?: number | null;
        created_at?: string;
        updated_at?: string | null;
        deleted_at?: string | null;
        position_name?: string | null;
        role_name?: string | null;
      }[];
    })[];
    phones: {
      id?: number;
      user_id?: number;
      phone_number: string;
      extension?: string | null;
      is_primary?: boolean;
      type: "mobile" | "office" | "home";
      country_code: string;
      sms?: boolean | null;
      created_at?: string;
      updated_at?: string | null;
      deleted_at?: string | null;
    }[];
    calendly_user_info?: string | null;
    has_password?: boolean | null;
  };
};
export type GetOrgUserByIdApiResponse = /** status 200 Show single user */ {
  id: number;
  email: string;
  normalized_email?: string | null;
  first_name: string;
  last_name: string;
  password?: string | null;
  login_attempts?: number;
  last_login?: string | null;
  last_failed_login?: string | null;
  forgot_password_token?: string | null;
  forgot_password_created_at?: string | null;
  mfa_code?: string | null;
  mfa_attempts?: number;
  mfa_attempted_at?: string | null;
  mfa_required?: boolean | null;
  internal_notes?: string | null;
  title?:
    | ("Mr." | "Mrs." | "Rabbi" | "Ms." | "Dr." | "Rebbetzin" | null)
    | null;
  availability?: string | null;
  calendly_link?: string | null;
  timer_is_active?: boolean | null;
  calendly_user_info?: string | null;
  default_org_id?: number | null;
  timezone?: string;
  created_at?: string;
  updated_at?: string | null;
  deleted_at?: string | null;
} & {
  orgs: ({
    id: number;
    pipedrive_org_id?: number | null;
    name?: string;
    phone?: string | null;
    country_code?: string | null;
    website?: string;
    formatted_address?: string | null;
    address?: string | null;
    address_line_2?: string | null;
    city?: string | null;
    state?: string | null;
    country?: string | null;
    zip?: string | null;
    it_info?: string | null;
    consultant_id?: number | null;
    sub_consultant_id?: number | null;
    account_manager_id?: number | null;
    internal_notes?: string | null;
    license_status_id?: number | null;
    code?: string;
    seq?: number;
    quickbooks_customer_id?: string | null;
    created_at?: string;
    updated_at?: string | null;
    deleted_at?: string | null;
  } & {
    user_roles: {
      id?: number;
      user_id?: number;
      role_id: number;
      is_primary?: boolean;
      org_id?: number | null;
      position_id?: number | null;
      preference_id?: number | null;
      created_at?: string;
      updated_at?: string | null;
      deleted_at?: string | null;
      position_name?: string | null;
      role_name?: string | null;
    }[];
  })[];
  phones: {
    id?: number;
    user_id?: number;
    phone_number: string;
    extension?: string | null;
    is_primary?: boolean;
    type: "mobile" | "office" | "home";
    country_code: string;
    sms?: boolean | null;
    created_at?: string;
    updated_at?: string | null;
    deleted_at?: string | null;
  }[];
  calendly_user_info?: string | null;
  has_password?: boolean | null;
};
export type GetOrgUserByIdApiArg = {
  /** record id */
  id: number;
  /** organization id */
  orgId: number;
};
export type UpdateUserInOrgApiResponse =
  /** status 204 An empty response */ void;
export type UpdateUserInOrgApiArg = {
  /** record id */
  id: number;
  /** organization id */
  orgId: number;
  /** Show single user */
  body: {
    id: number;
    email: string;
    normalized_email?: string | null;
    first_name: string;
    last_name: string;
    password?: string | null;
    login_attempts?: number;
    last_login?: string | null;
    last_failed_login?: string | null;
    forgot_password_token?: string | null;
    forgot_password_created_at?: string | null;
    mfa_code?: string | null;
    mfa_attempts?: number;
    mfa_attempted_at?: string | null;
    mfa_required?: boolean | null;
    internal_notes?: string | null;
    title?:
      | ("Mr." | "Mrs." | "Rabbi" | "Ms." | "Dr." | "Rebbetzin" | null)
      | null;
    availability?: string | null;
    calendly_link?: string | null;
    timer_is_active?: boolean | null;
    calendly_user_info?: string | null;
    default_org_id?: number | null;
    timezone?: string;
    created_at?: string;
    updated_at?: string | null;
    deleted_at?: string | null;
  } & {
    orgs: ({
      id: number;
      pipedrive_org_id?: number | null;
      name?: string;
      phone?: string | null;
      country_code?: string | null;
      website?: string;
      formatted_address?: string | null;
      address?: string | null;
      address_line_2?: string | null;
      city?: string | null;
      state?: string | null;
      country?: string | null;
      zip?: string | null;
      it_info?: string | null;
      consultant_id?: number | null;
      sub_consultant_id?: number | null;
      account_manager_id?: number | null;
      internal_notes?: string | null;
      license_status_id?: number | null;
      code?: string;
      seq?: number;
      quickbooks_customer_id?: string | null;
      created_at?: string;
      updated_at?: string | null;
      deleted_at?: string | null;
    } & {
      user_roles: {
        id?: number;
        user_id?: number;
        role_id: number;
        is_primary?: boolean;
        org_id?: number | null;
        position_id?: number | null;
        preference_id?: number | null;
        created_at?: string;
        updated_at?: string | null;
        deleted_at?: string | null;
        position_name?: string | null;
        role_name?: string | null;
      }[];
    })[];
    phones: {
      id?: number;
      user_id?: number;
      phone_number: string;
      extension?: string | null;
      is_primary?: boolean;
      type: "mobile" | "office" | "home";
      country_code: string;
      sms?: boolean | null;
      created_at?: string;
      updated_at?: string | null;
      deleted_at?: string | null;
    }[];
    calendly_user_info?: string | null;
    has_password?: boolean | null;
  };
};
export type DeleteOrgUserApiResponse = /** status 204 An empty response */ void;
export type DeleteOrgUserApiArg = {
  /** record id */
  id: number;
  /** organization id */
  orgId: number;
};
export type UpdateUserProfileInOrgApiResponse =
  /** status 204 An empty response */ void;
export type UpdateUserProfileInOrgApiArg = {
  /** organization id */
  orgId: number;
  /** Show single user */
  body: {
    id: number;
    email: string;
    normalized_email?: string | null;
    first_name: string;
    last_name: string;
    password?: string | null;
    login_attempts?: number;
    last_login?: string | null;
    last_failed_login?: string | null;
    forgot_password_token?: string | null;
    forgot_password_created_at?: string | null;
    mfa_code?: string | null;
    mfa_attempts?: number;
    mfa_attempted_at?: string | null;
    mfa_required?: boolean | null;
    internal_notes?: string | null;
    title?:
      | ("Mr." | "Mrs." | "Rabbi" | "Ms." | "Dr." | "Rebbetzin" | null)
      | null;
    availability?: string | null;
    calendly_link?: string | null;
    timer_is_active?: boolean | null;
    calendly_user_info?: string | null;
    default_org_id?: number | null;
    timezone?: string;
    created_at?: string;
    updated_at?: string | null;
    deleted_at?: string | null;
  } & {
    orgs: ({
      id: number;
      pipedrive_org_id?: number | null;
      name?: string;
      phone?: string | null;
      country_code?: string | null;
      website?: string;
      formatted_address?: string | null;
      address?: string | null;
      address_line_2?: string | null;
      city?: string | null;
      state?: string | null;
      country?: string | null;
      zip?: string | null;
      it_info?: string | null;
      consultant_id?: number | null;
      sub_consultant_id?: number | null;
      account_manager_id?: number | null;
      internal_notes?: string | null;
      license_status_id?: number | null;
      code?: string;
      seq?: number;
      quickbooks_customer_id?: string | null;
      created_at?: string;
      updated_at?: string | null;
      deleted_at?: string | null;
    } & {
      user_roles: {
        id?: number;
        user_id?: number;
        role_id: number;
        is_primary?: boolean;
        org_id?: number | null;
        position_id?: number | null;
        preference_id?: number | null;
        created_at?: string;
        updated_at?: string | null;
        deleted_at?: string | null;
        position_name?: string | null;
        role_name?: string | null;
      }[];
    })[];
    phones: {
      id?: number;
      user_id?: number;
      phone_number: string;
      extension?: string | null;
      is_primary?: boolean;
      type: "mobile" | "office" | "home";
      country_code: string;
      sms?: boolean | null;
      created_at?: string;
      updated_at?: string | null;
      deleted_at?: string | null;
    }[];
    calendly_user_info?: string | null;
    has_password?: boolean | null;
  };
};
export type GetOrgLinksByIdApiResponse = /** status 200 OrganizationLinks */ {
  count: number;
  rows: {
    id: number;
    label?: string | null;
    link: string;
    org_id: number;
    sort?: number | null;
    created_at?: string | null;
    updated_at?: string | null;
    deleted_at?: string | null;
  }[];
};
export type GetOrgLinksByIdApiArg = {
  /** order by */
  sort?: string;
  /** filter by */
  field?: string;
  /** filter by */
  filter?: string;
  /** range for query limit */
  range?: number[];
  /** link type */
  linkType?: "and" | "or";
  /** search Query param */
  q?: string;
  /** organization id */
  orgId: number;
};
export type GetOrgAnnouncementsApiResponse =
  /** status 200 org announcement response */ {
    announcements: {
      id?: number | null;
      announcement: string;
      type: "informational" | "alert";
      start_date: string;
      end_date: string;
      button?: string | null;
      button_link?: string | null;
      created_by?: number;
      is_global?: boolean | null;
    }[];
  };
export type GetOrgAnnouncementsApiArg = {
  /** organization id */
  orgId: number;
};
export type GetLicensesAndStatusesApiResponse = /** status 200 Licenses */ {
  licenses: ({
    id: number;
    name: string;
  } & {
    license_statuses: {
      id: number;
      name: string;
      license_id: number;
    }[];
  })[];
};
export type GetLicensesAndStatusesApiArg = void;
export type UpdateOrgsConsultantApiResponse =
  /** status 204 An empty response */ void;
export type UpdateOrgsConsultantApiArg = {
  /** user */
  body: {
    orgs: number[];
    consultant_id?: number | null;
    sub_consultant_id?: number | null;
  };
};
export type BulkDeleteOrgsSubConsultantApiResponse =
  /** status 204 An empty response */ void;
export type BulkDeleteOrgsSubConsultantApiArg = {
  /** user */
  body: {
    orgs: number[];
    consultant_id?: number | null;
    sub_consultant_id?: number | null;
  };
};
export type GetOrgTasksApiResponse =
  /** status 200 catch all response response */ {
    count: number;
    rows: {
      id: number;
      status_id: number;
      topic_id: number;
      org_id?: number;
      title: string;
      description?: string | null;
      priority?: "normal" | "high" | "critical";
      internal_priority?: "low" | "medium" | "high";
      assigned_to: number;
      reported_by: number;
      type: "task" | "quick_question" | "internal_task" | "custom_work";
      requested_by_date?: string | null;
      instant_meeting_url?: string | null;
      instant_meeting_occurred?: boolean | null;
      client_assignee?: number | null;
      billing_status?: ("no_charge" | "making_standard" | null) | null;
      description_search?: string | null;
      action_pending?: ("admire" | "client" | null) | null;
      code?: string | null;
      allow_org_change?: boolean;
      created_at: string;
      updated_at?: string | null;
      deleted_at?: string | null;
      task_watchers: {
        id?: number;
        task_id?: number;
        user_id: number;
        created_at?: string;
        updated_at?: string | null;
        deleted_at?: string | null;
      }[];
      pending_session_dates?:
        | {
            value?: string | null;
            inclusive?: boolean | null;
          }[]
        | null;
      session_start_time?: string;
      session_title?: string | null;
      session_end_time?: string | null;
      session_duration?: number | null;
      has_active_session?: boolean;
      session_status_id?: number | null;
      task_chat_count: number;
      media_count: number;
      new_chats_count: number;
      time_spent?: number | null;
      /** task_chat schema */
      latest_chat?: {
        id?: number;
        user_id?: number;
        message?: string;
        task_id?: number;
        is_internal?: boolean;
        has_media?: boolean;
        message_search?: string | null;
        created_at?: string;
        updated_at?: string | null;
        deleted_at?: string | null;
      } | null;
    }[];
  };
export type GetOrgTasksApiArg = {
  /** assignees to filter by */
  assignees?: number[];
  /** defines action_pending */
  awaiting?: "admire" | "client";
  /** range for query limit */
  range?: number[];
  /** task type */
  type?: ("quick_question" | "on_session" | "custom_work" | "unscheduled")[];
  /** topics to filter by */
  topics?: number[];
  /** date range to filter by */
  dateRange?: string[];
  /** statuses to filter by */
  statuses?: number[];
  /** organization id */
  orgId: number;
};
export type GetOrgActivitiesApiResponse =
  /** status 200 activities response */ {
    activities: ({
      id: number;
      type: string;
      title: string;
      entity: string;
      entity_id: number;
      created_by: number;
      value?: string | null;
      old_value?: string | null;
      created_at: string;
    } & {
      created_by_user: {
        id: number;
        first_name: string;
        last_name: string;
      };
    })[];
  };
export type GetOrgActivitiesApiArg = {
  /** organization id */
  orgId: number;
};
export type GetOrgUsersListApiResponse = /** status 200 users list response */ {
  data: {
    id: number;
    email: string;
    normalized_email?: string | null;
    first_name: string;
    last_name: string;
    password?: string | null;
    login_attempts?: number;
    last_login?: string | null;
    last_failed_login?: string | null;
    forgot_password_token?: string | null;
    forgot_password_created_at?: string | null;
    mfa_code?: string | null;
    mfa_attempts?: number;
    mfa_attempted_at?: string | null;
    mfa_required?: boolean | null;
    internal_notes?: string | null;
    title?:
      | ("Mr." | "Mrs." | "Rabbi" | "Ms." | "Dr." | "Rebbetzin" | null)
      | null;
    availability?: string | null;
    calendly_link?: string | null;
    timer_is_active?: boolean | null;
    calendly_user_info?: string | null;
    default_org_id?: number | null;
    timezone?: string;
    created_at?: string;
    updated_at?: string | null;
    deleted_at?: string | null;
  }[];
};
export type GetOrgUsersListApiArg = {
  /** organization id */
  orgId: number;
};
export type ApplyTemplateApiResponse = /** status 204 An empty response */ void;
export type ApplyTemplateApiArg = {
  /** organization id */
  orgId: number;
  /** announcement body */
  body: {
    template_id: number;
  };
};
export const {
  useGetOrgsListQuery,
  useLazyGetOrgsListQuery,
  useGetMyOrgsListQuery,
  useLazyGetMyOrgsListQuery,
  useGetMyOrgsOverviewQuery,
  useLazyGetMyOrgsOverviewQuery,
  useGetUsersInOrgQuery,
  useLazyGetUsersInOrgQuery,
  useGetOrgsQuery,
  useLazyGetOrgsQuery,
  useCreateOrgMutation,
  useGetOrgByIdInternalQuery,
  useLazyGetOrgByIdInternalQuery,
  useGetOrgByIdQuery,
  useLazyGetOrgByIdQuery,
  useUpdateOrgMutation,
  useDeleteOrgMutation,
  useGetOrgUsersByIdQuery,
  useLazyGetOrgUsersByIdQuery,
  useAddUserToOrgMutation,
  useGetOrgUserByIdQuery,
  useLazyGetOrgUserByIdQuery,
  useUpdateUserInOrgMutation,
  useDeleteOrgUserMutation,
  useUpdateUserProfileInOrgMutation,
  useGetOrgLinksByIdQuery,
  useLazyGetOrgLinksByIdQuery,
  useGetOrgAnnouncementsQuery,
  useLazyGetOrgAnnouncementsQuery,
  useGetLicensesAndStatusesQuery,
  useLazyGetLicensesAndStatusesQuery,
  useUpdateOrgsConsultantMutation,
  useBulkDeleteOrgsSubConsultantMutation,
  useGetOrgTasksQuery,
  useLazyGetOrgTasksQuery,
  useGetOrgActivitiesQuery,
  useLazyGetOrgActivitiesQuery,
  useGetOrgUsersListQuery,
  useLazyGetOrgUsersListQuery,
  useApplyTemplateMutation,
} = injectedRtkApi;
