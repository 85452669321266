import { useEffect, useState } from "react";
import { useCreateTaskChatMutation } from "../../state/rtk-query/state/tasks";
import { TaskValidationProps } from "../task";
import { useSelector } from "react-redux";
import { authSelectors } from "../../state";

export function useOptimisticChats(
  taskId: number,
  taskChats: TaskValidationProps["taskChats"]["data"],
) {
  const [
    createChat,
    { isLoading: isCreatingChat, originalArgs, isError: errorCreatingChat },
  ] = useCreateTaskChatMutation();
  const [chats, setChats] = useState(taskChats?.rows || []);

  useEffect(() => {
    setChats(taskChats?.rows || []);
  }, [taskChats?.rows]);

  const userInfo = useSelector(authSelectors.userInfo);

  useEffect(() => {
    if (isCreatingChat && originalArgs?.body) {
      setChats((prev) => [
        {
          ...originalArgs.body,
          has_media: !!originalArgs.body.has_media,
          user: userInfo,
          task_id: taskId,
          created_at: new Date().toISOString(),
          id: 0,
        },
        ...prev,
      ]);
    }
  }, [isCreatingChat]);

  useEffect(() => {
    if (errorCreatingChat) {
      setChats(taskChats?.rows || []);
    }
  }, [errorCreatingChat]);
  return { chats, createChat };
}
