import { api } from "../api";
export const addTagTypes = [
  "users",
  "reset_password",
  "multi_factor_auth",
  "tasks",
  "tasks_chat",
  "tasks_media",
  "timer",
] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      authLogin: build.mutation<AuthLoginApiResponse, AuthLoginApiArg>({
        query: (queryArg) => ({
          url: `/auth/login`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["users"],
      }),
      authRefreshToken: build.query<
        AuthRefreshTokenApiResponse,
        AuthRefreshTokenApiArg
      >({
        query: () => ({ url: `/auth/refresh-token` }),
        providesTags: ["users"],
      }),
      authLogout: build.query<AuthLogoutApiResponse, AuthLogoutApiArg>({
        query: () => ({ url: `/auth/logout` }),
        providesTags: ["users"],
      }),
      authSocialLogin: build.mutation<
        AuthSocialLoginApiResponse,
        AuthSocialLoginApiArg
      >({
        query: (queryArg) => ({
          url: `/auth/social-login`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["users"],
      }),
      authForgotPassword: build.mutation<
        AuthForgotPasswordApiResponse,
        AuthForgotPasswordApiArg
      >({
        query: (queryArg) => ({
          url: `/auth/password/forgot`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["users", "reset_password"],
      }),
      authInviteEmail: build.mutation<
        AuthInviteEmailApiResponse,
        AuthInviteEmailApiArg
      >({
        query: (queryArg) => ({
          url: `/auth/password/invite`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["users", "reset_password"],
      }),
      authResetPassword: build.mutation<
        AuthResetPasswordApiResponse,
        AuthResetPasswordApiArg
      >({
        query: (queryArg) => ({
          url: `/auth/password/reset`,
          method: "PUT",
          body: queryArg.body,
        }),
        invalidatesTags: ["users"],
      }),
      authMultiFactorAuth: build.mutation<
        AuthMultiFactorAuthApiResponse,
        AuthMultiFactorAuthApiArg
      >({
        query: (queryArg) => ({
          url: `/auth/multi-factor-auth`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["multi_factor_auth"],
      }),
      authResendMfaCode: build.query<
        AuthResendMfaCodeApiResponse,
        AuthResendMfaCodeApiArg
      >({
        query: (queryArg) => ({
          url: `/auth/resend-mfa-code`,
          params: { type: queryArg["type"] },
        }),
        providesTags: ["multi_factor_auth"],
      }),
      getAdmireUsers: build.query<
        GetAdmireUsersApiResponse,
        GetAdmireUsersApiArg
      >({
        query: () => ({ url: `/users/admire` }),
        providesTags: ["users"],
      }),
      manageAssignees: build.mutation<
        ManageAssigneesApiResponse,
        ManageAssigneesApiArg
      >({
        query: (queryArg) => ({
          url: `/org/${queryArg.orgId}/users/manage/${queryArg.id}`,
          method: "PUT",
          body: queryArg.body,
        }),
        invalidatesTags: ["tasks", "tasks_chat", "tasks_media"],
      }),
      getUser: build.query<GetUserApiResponse, GetUserApiArg>({
        query: (queryArg) => ({ url: `/users/${queryArg.id}` }),
        providesTags: ["users"],
      }),
      updateUser: build.mutation<UpdateUserApiResponse, UpdateUserApiArg>({
        query: (queryArg) => ({
          url: `/users/${queryArg.id}`,
          method: "PUT",
          body: queryArg.body,
        }),
        invalidatesTags: ["users"],
      }),
      deleteUser: build.mutation<DeleteUserApiResponse, DeleteUserApiArg>({
        query: (queryArg) => ({
          url: `/users/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["users"],
      }),
      getUsers: build.query<GetUsersApiResponse, GetUsersApiArg>({
        query: (queryArg) => ({
          url: `/users`,
          params: {
            sort: queryArg.sort,
            field: queryArg.field,
            filter: queryArg.filter,
            range: queryArg.range,
            linkType: queryArg.linkType,
            q: queryArg.q,
            userType: queryArg.userType,
            inactive: queryArg.inactive,
          },
        }),
        providesTags: ["users"],
      }),
      createUser: build.mutation<CreateUserApiResponse, CreateUserApiArg>({
        query: (queryArg) => ({
          url: `/users`,
          method: "POST",
          body: queryArg.body,
        }),
        invalidatesTags: ["users"],
      }),
      getConsultantsAndAms: build.query<
        GetConsultantsAndAmsApiResponse,
        GetConsultantsAndAmsApiArg
      >({
        query: () => ({ url: `/users_consultants_and_ams` }),
        providesTags: ["users"],
      }),
      getConsultants: build.query<
        GetConsultantsApiResponse,
        GetConsultantsApiArg
      >({
        query: () => ({ url: `/consultants` }),
        providesTags: ["users"],
      }),
      getAccountManagers: build.query<
        GetAccountManagersApiResponse,
        GetAccountManagersApiArg
      >({
        query: () => ({ url: `/account_managers` }),
        providesTags: ["users"],
      }),
      getAdmireUsersList: build.query<
        GetAdmireUsersListApiResponse,
        GetAdmireUsersListApiArg
      >({
        query: () => ({ url: `/admire_users_list` }),
        providesTags: ["users"],
      }),
      getUserProfile: build.query<
        GetUserProfileApiResponse,
        GetUserProfileApiArg
      >({
        query: () => ({ url: `/profile` }),
        providesTags: ["users"],
      }),
      updateUserProfile: build.mutation<
        UpdateUserProfileApiResponse,
        UpdateUserProfileApiArg
      >({
        query: (queryArg) => ({
          url: `/profile`,
          method: "PUT",
          body: queryArg.body,
        }),
        invalidatesTags: ["users"],
      }),
      getUserActivity: build.query<
        GetUserActivityApiResponse,
        GetUserActivityApiArg
      >({
        query: (queryArg) => ({ url: `/users/${queryArg.id}/activity` }),
        providesTags: ["users"],
      }),
      impersonate: build.mutation<ImpersonateApiResponse, ImpersonateApiArg>({
        query: (queryArg) => ({
          url: `/users/${queryArg.id}/impersonate`,
          method: "POST",
        }),
        invalidatesTags: ["users"],
      }),
      turnOffUserTimer: build.mutation<
        TurnOffUserTimerApiResponse,
        TurnOffUserTimerApiArg
      >({
        query: (queryArg) => ({
          url: `/users/${queryArg.id}/timer`,
          method: "POST",
          params: { deactivateTimer: queryArg.deactivateTimer },
        }),
        invalidatesTags: ["timer", "users"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as actions };
export type AuthLoginApiResponse = /** status 200 jwt permissions token */ {
  token: string;
  refreshToken?: string;
  expiration?: string;
  user?: {
    id: number;
    email: string;
    normalized_email?: string | null;
    first_name: string;
    last_name: string;
    password?: string | null;
    login_attempts?: number;
    last_login?: string | null;
    last_failed_login?: string | null;
    forgot_password_token?: string | null;
    forgot_password_created_at?: string | null;
    mfa_code?: string | null;
    mfa_attempts?: number;
    mfa_attempted_at?: string | null;
    mfa_required?: boolean | null;
    internal_notes?: string | null;
    title?:
      | ("Mr." | "Mrs." | "Rabbi" | "Ms." | "Dr." | "Rebbetzin" | null)
      | null;
    availability?: string | null;
    calendly_link?: string | null;
    timer_is_active?: boolean | null;
    calendly_user_info?: string | null;
    default_org_id?: number | null;
    timezone?: string;
    created_at?: string;
    updated_at?: string | null;
    deleted_at?: string | null;
  } & {
    orgs: ({
      id: number;
      pipedrive_org_id?: number | null;
      name?: string;
      phone?: string | null;
      country_code?: string | null;
      website?: string;
      formatted_address?: string | null;
      address?: string | null;
      address_line_2?: string | null;
      city?: string | null;
      state?: string | null;
      country?: string | null;
      zip?: string | null;
      it_info?: string | null;
      consultant_id?: number | null;
      sub_consultant_id?: number | null;
      account_manager_id?: number | null;
      internal_notes?: string | null;
      license_status_id?: number | null;
      code?: string;
      seq?: number;
      quickbooks_customer_id?: string | null;
      created_at?: string;
      updated_at?: string | null;
      deleted_at?: string | null;
    } & {
      user_roles: {
        id?: number;
        user_id?: number;
        role_id: number;
        is_primary?: boolean;
        org_id?: number | null;
        position_id?: number | null;
        preference_id?: number | null;
        created_at?: string;
        updated_at?: string | null;
        deleted_at?: string | null;
        position_name?: string | null;
        role_name?: string | null;
      }[];
    })[];
    phones: {
      id?: number;
      user_id?: number;
      phone_number: string;
      extension?: string | null;
      is_primary?: boolean;
      type: "mobile" | "office" | "home";
      country_code: string;
      sms?: boolean | null;
      created_at?: string;
      updated_at?: string | null;
      deleted_at?: string | null;
    }[];
    calendly_user_info?: string | null;
    has_password?: boolean | null;
  };
};
export type AuthLoginApiArg = {
  /** user login info */
  body: {
    username: string;
    password: string;
  };
};
export type AuthRefreshTokenApiResponse =
  /** status 200 jwt permissions token */ {
    token: string;
    refreshToken?: string;
    expiration?: string;
    user?: {
      id: number;
      email: string;
      normalized_email?: string | null;
      first_name: string;
      last_name: string;
      password?: string | null;
      login_attempts?: number;
      last_login?: string | null;
      last_failed_login?: string | null;
      forgot_password_token?: string | null;
      forgot_password_created_at?: string | null;
      mfa_code?: string | null;
      mfa_attempts?: number;
      mfa_attempted_at?: string | null;
      mfa_required?: boolean | null;
      internal_notes?: string | null;
      title?:
        | ("Mr." | "Mrs." | "Rabbi" | "Ms." | "Dr." | "Rebbetzin" | null)
        | null;
      availability?: string | null;
      calendly_link?: string | null;
      timer_is_active?: boolean | null;
      calendly_user_info?: string | null;
      default_org_id?: number | null;
      timezone?: string;
      created_at?: string;
      updated_at?: string | null;
      deleted_at?: string | null;
    } & {
      orgs: ({
        id: number;
        pipedrive_org_id?: number | null;
        name?: string;
        phone?: string | null;
        country_code?: string | null;
        website?: string;
        formatted_address?: string | null;
        address?: string | null;
        address_line_2?: string | null;
        city?: string | null;
        state?: string | null;
        country?: string | null;
        zip?: string | null;
        it_info?: string | null;
        consultant_id?: number | null;
        sub_consultant_id?: number | null;
        account_manager_id?: number | null;
        internal_notes?: string | null;
        license_status_id?: number | null;
        code?: string;
        seq?: number;
        quickbooks_customer_id?: string | null;
        created_at?: string;
        updated_at?: string | null;
        deleted_at?: string | null;
      } & {
        user_roles: {
          id?: number;
          user_id?: number;
          role_id: number;
          is_primary?: boolean;
          org_id?: number | null;
          position_id?: number | null;
          preference_id?: number | null;
          created_at?: string;
          updated_at?: string | null;
          deleted_at?: string | null;
          position_name?: string | null;
          role_name?: string | null;
        }[];
      })[];
      phones: {
        id?: number;
        user_id?: number;
        phone_number: string;
        extension?: string | null;
        is_primary?: boolean;
        type: "mobile" | "office" | "home";
        country_code: string;
        sms?: boolean | null;
        created_at?: string;
        updated_at?: string | null;
        deleted_at?: string | null;
      }[];
      calendly_user_info?: string | null;
      has_password?: boolean | null;
    };
  };
export type AuthRefreshTokenApiArg = void;
export type AuthLogoutApiResponse = /** status 200 Status check */ {
  status: string;
};
export type AuthLogoutApiArg = void;
export type AuthSocialLoginApiResponse =
  /** status 200 jwt permissions token */ {
    token: string;
    refreshToken?: string;
    expiration?: string;
    user?: {
      id: number;
      email: string;
      normalized_email?: string | null;
      first_name: string;
      last_name: string;
      password?: string | null;
      login_attempts?: number;
      last_login?: string | null;
      last_failed_login?: string | null;
      forgot_password_token?: string | null;
      forgot_password_created_at?: string | null;
      mfa_code?: string | null;
      mfa_attempts?: number;
      mfa_attempted_at?: string | null;
      mfa_required?: boolean | null;
      internal_notes?: string | null;
      title?:
        | ("Mr." | "Mrs." | "Rabbi" | "Ms." | "Dr." | "Rebbetzin" | null)
        | null;
      availability?: string | null;
      calendly_link?: string | null;
      timer_is_active?: boolean | null;
      calendly_user_info?: string | null;
      default_org_id?: number | null;
      timezone?: string;
      created_at?: string;
      updated_at?: string | null;
      deleted_at?: string | null;
    } & {
      orgs: ({
        id: number;
        pipedrive_org_id?: number | null;
        name?: string;
        phone?: string | null;
        country_code?: string | null;
        website?: string;
        formatted_address?: string | null;
        address?: string | null;
        address_line_2?: string | null;
        city?: string | null;
        state?: string | null;
        country?: string | null;
        zip?: string | null;
        it_info?: string | null;
        consultant_id?: number | null;
        sub_consultant_id?: number | null;
        account_manager_id?: number | null;
        internal_notes?: string | null;
        license_status_id?: number | null;
        code?: string;
        seq?: number;
        quickbooks_customer_id?: string | null;
        created_at?: string;
        updated_at?: string | null;
        deleted_at?: string | null;
      } & {
        user_roles: {
          id?: number;
          user_id?: number;
          role_id: number;
          is_primary?: boolean;
          org_id?: number | null;
          position_id?: number | null;
          preference_id?: number | null;
          created_at?: string;
          updated_at?: string | null;
          deleted_at?: string | null;
          position_name?: string | null;
          role_name?: string | null;
        }[];
      })[];
      phones: {
        id?: number;
        user_id?: number;
        phone_number: string;
        extension?: string | null;
        is_primary?: boolean;
        type: "mobile" | "office" | "home";
        country_code: string;
        sms?: boolean | null;
        created_at?: string;
        updated_at?: string | null;
        deleted_at?: string | null;
      }[];
      calendly_user_info?: string | null;
      has_password?: boolean | null;
    };
  };
export type AuthSocialLoginApiArg = {
  /** user social login info */
  body: {
    sign_in_type: "google" | "microsoft";
    credential: string;
    [key: string]: any;
  };
};
export type AuthForgotPasswordApiResponse = /** status 200 Status check */ {
  status: string;
};
export type AuthForgotPasswordApiArg = {
  /** user forgot password */
  body: {
    email: string;
  };
};
export type AuthInviteEmailApiResponse = /** status 200 Status check */ {
  status: string;
};
export type AuthInviteEmailApiArg = {
  /** user forgot password */
  body: {
    email: string;
  };
};
export type AuthResetPasswordApiResponse = /** status 200 Status check */ {
  status: string;
};
export type AuthResetPasswordApiArg = {
  /** user reset password */
  body: {
    token: string;
    newPassword: string;
  };
};
export type AuthMultiFactorAuthApiResponse =
  /** status 200 jwt permissions token */ {
    token: string;
    refreshToken?: string;
    expiration?: string;
    user?: {
      id: number;
      email: string;
      normalized_email?: string | null;
      first_name: string;
      last_name: string;
      password?: string | null;
      login_attempts?: number;
      last_login?: string | null;
      last_failed_login?: string | null;
      forgot_password_token?: string | null;
      forgot_password_created_at?: string | null;
      mfa_code?: string | null;
      mfa_attempts?: number;
      mfa_attempted_at?: string | null;
      mfa_required?: boolean | null;
      internal_notes?: string | null;
      title?:
        | ("Mr." | "Mrs." | "Rabbi" | "Ms." | "Dr." | "Rebbetzin" | null)
        | null;
      availability?: string | null;
      calendly_link?: string | null;
      timer_is_active?: boolean | null;
      calendly_user_info?: string | null;
      default_org_id?: number | null;
      timezone?: string;
      created_at?: string;
      updated_at?: string | null;
      deleted_at?: string | null;
    } & {
      orgs: ({
        id: number;
        pipedrive_org_id?: number | null;
        name?: string;
        phone?: string | null;
        country_code?: string | null;
        website?: string;
        formatted_address?: string | null;
        address?: string | null;
        address_line_2?: string | null;
        city?: string | null;
        state?: string | null;
        country?: string | null;
        zip?: string | null;
        it_info?: string | null;
        consultant_id?: number | null;
        sub_consultant_id?: number | null;
        account_manager_id?: number | null;
        internal_notes?: string | null;
        license_status_id?: number | null;
        code?: string;
        seq?: number;
        quickbooks_customer_id?: string | null;
        created_at?: string;
        updated_at?: string | null;
        deleted_at?: string | null;
      } & {
        user_roles: {
          id?: number;
          user_id?: number;
          role_id: number;
          is_primary?: boolean;
          org_id?: number | null;
          position_id?: number | null;
          preference_id?: number | null;
          created_at?: string;
          updated_at?: string | null;
          deleted_at?: string | null;
          position_name?: string | null;
          role_name?: string | null;
        }[];
      })[];
      phones: {
        id?: number;
        user_id?: number;
        phone_number: string;
        extension?: string | null;
        is_primary?: boolean;
        type: "mobile" | "office" | "home";
        country_code: string;
        sms?: boolean | null;
        created_at?: string;
        updated_at?: string | null;
        deleted_at?: string | null;
      }[];
      calendly_user_info?: string | null;
      has_password?: boolean | null;
    };
  };
export type AuthMultiFactorAuthApiArg = {
  /** mfa code verify */
  body: {
    mfaCode: string;
    rememberMe: boolean;
  };
};
export type AuthResendMfaCodeApiResponse =
  /** status 204 An empty response */ void;
export type AuthResendMfaCodeApiArg = {
  /** defines the type */
  type?: "email" | "sms";
};
export type GetAdmireUsersApiResponse =
  /** status 200 catch all response response */ {
    count: number;
    rows: ({
      id: number;
      email: string;
      first_name: string;
      last_name: string;
      mfa_required?: boolean | null;
      is_primary?: boolean | null;
      title?:
        | ("Mr." | "Mrs." | "Ms." | "Dr." | "Rabbi" | "Rebbetzin" | null)
        | null;
      internal_notes?: string | null;
      availability?: string | null;
      phones: {
        id?: number;
        user_id?: number;
        phone_number: string;
        country_code: string;
        type: "mobile" | "home" | "office";
        extension?: string | null;
        sms?: boolean | null;
        is_primary?: boolean | null;
        created_at?: string | null;
        updated_at?: string | null;
        deleted_at?: string | null;
      }[];
      created_at?: string | null;
      has_password?: boolean;
      timer_is_active?: boolean | null;
    } & {
      user_roles: ({
        user_id: number;
        role_id: number;
        org_id: number;
        position_id?: number | null;
        is_primary?: boolean | null;
      } & {
        role?: {
          id?: number;
          internal_name: string;
          name: string;
          type?: "internal" | "external";
          created_at?: string | null;
          updated_at?: string | null;
          deleted_at?: string | null;
        };
        /** organizations */
        org?: {
          id: number;
          pipedrive_org_id?: number | null;
          name: string;
          phone?: string;
          country_code?: string | null;
          website?: string;
          address?: string;
          consultant_id?: number | null;
          sub_consultant_id?: number | null;
          account_manager_id?: number | null;
          license_status_id?: number | null;
          it_info?: string | null;
          internal_notes?: string | null;
          code?: string;
          created_at?: string | null;
          updated_at?: string | null;
          deleted_at?: string | null;
          quickbooks_customer_id?: string | null;
        };
      })[];
      calendly_link?: string | null;
      calendly_user_info?: string | null;
      default_org_id?: number | null;
    })[];
  };
export type GetAdmireUsersApiArg = void;
export type ManageAssigneesApiResponse =
  /** status 204 An empty response */ void;
export type ManageAssigneesApiArg = {
  /** record id */
  id: number;
  /** organization id */
  orgId: number;
  /** request body for changing assignees on a task or session */
  body: {
    admire_assignee_id: number;
    client_assignee_id: number;
    other_users?: number[];
    assignee_type: "session" | "task";
  };
};
export type GetUserApiResponse = /** status 200 Show single user */ {
  id: number;
  email: string;
  normalized_email?: string | null;
  first_name: string;
  last_name: string;
  password?: string | null;
  login_attempts?: number;
  last_login?: string | null;
  last_failed_login?: string | null;
  forgot_password_token?: string | null;
  forgot_password_created_at?: string | null;
  mfa_code?: string | null;
  mfa_attempts?: number;
  mfa_attempted_at?: string | null;
  mfa_required?: boolean | null;
  internal_notes?: string | null;
  title?:
    | ("Mr." | "Mrs." | "Rabbi" | "Ms." | "Dr." | "Rebbetzin" | null)
    | null;
  availability?: string | null;
  calendly_link?: string | null;
  timer_is_active?: boolean | null;
  calendly_user_info?: string | null;
  default_org_id?: number | null;
  timezone?: string;
  created_at?: string;
  updated_at?: string | null;
  deleted_at?: string | null;
} & {
  orgs: ({
    id: number;
    pipedrive_org_id?: number | null;
    name?: string;
    phone?: string | null;
    country_code?: string | null;
    website?: string;
    formatted_address?: string | null;
    address?: string | null;
    address_line_2?: string | null;
    city?: string | null;
    state?: string | null;
    country?: string | null;
    zip?: string | null;
    it_info?: string | null;
    consultant_id?: number | null;
    sub_consultant_id?: number | null;
    account_manager_id?: number | null;
    internal_notes?: string | null;
    license_status_id?: number | null;
    code?: string;
    seq?: number;
    quickbooks_customer_id?: string | null;
    created_at?: string;
    updated_at?: string | null;
    deleted_at?: string | null;
  } & {
    user_roles: {
      id?: number;
      user_id?: number;
      role_id: number;
      is_primary?: boolean;
      org_id?: number | null;
      position_id?: number | null;
      preference_id?: number | null;
      created_at?: string;
      updated_at?: string | null;
      deleted_at?: string | null;
      position_name?: string | null;
      role_name?: string | null;
    }[];
  })[];
  phones: {
    id?: number;
    user_id?: number;
    phone_number: string;
    extension?: string | null;
    is_primary?: boolean;
    type: "mobile" | "office" | "home";
    country_code: string;
    sms?: boolean | null;
    created_at?: string;
    updated_at?: string | null;
    deleted_at?: string | null;
  }[];
  calendly_user_info?: string | null;
  has_password?: boolean | null;
};
export type GetUserApiArg = {
  /** record id */
  id: number;
};
export type UpdateUserApiResponse = /** status 204 An empty response */ void;
export type UpdateUserApiArg = {
  /** record id */
  id: number;
  /** Show single user */
  body: {
    id: number;
    email: string;
    normalized_email?: string | null;
    first_name: string;
    last_name: string;
    password?: string | null;
    login_attempts?: number;
    last_login?: string | null;
    last_failed_login?: string | null;
    forgot_password_token?: string | null;
    forgot_password_created_at?: string | null;
    mfa_code?: string | null;
    mfa_attempts?: number;
    mfa_attempted_at?: string | null;
    mfa_required?: boolean | null;
    internal_notes?: string | null;
    title?:
      | ("Mr." | "Mrs." | "Rabbi" | "Ms." | "Dr." | "Rebbetzin" | null)
      | null;
    availability?: string | null;
    calendly_link?: string | null;
    timer_is_active?: boolean | null;
    calendly_user_info?: string | null;
    default_org_id?: number | null;
    timezone?: string;
    created_at?: string;
    updated_at?: string | null;
    deleted_at?: string | null;
  } & {
    orgs: ({
      id: number;
      pipedrive_org_id?: number | null;
      name?: string;
      phone?: string | null;
      country_code?: string | null;
      website?: string;
      formatted_address?: string | null;
      address?: string | null;
      address_line_2?: string | null;
      city?: string | null;
      state?: string | null;
      country?: string | null;
      zip?: string | null;
      it_info?: string | null;
      consultant_id?: number | null;
      sub_consultant_id?: number | null;
      account_manager_id?: number | null;
      internal_notes?: string | null;
      license_status_id?: number | null;
      code?: string;
      seq?: number;
      quickbooks_customer_id?: string | null;
      created_at?: string;
      updated_at?: string | null;
      deleted_at?: string | null;
    } & {
      user_roles: {
        id?: number;
        user_id?: number;
        role_id: number;
        is_primary?: boolean;
        org_id?: number | null;
        position_id?: number | null;
        preference_id?: number | null;
        created_at?: string;
        updated_at?: string | null;
        deleted_at?: string | null;
        position_name?: string | null;
        role_name?: string | null;
      }[];
    })[];
    phones: {
      id?: number;
      user_id?: number;
      phone_number: string;
      extension?: string | null;
      is_primary?: boolean;
      type: "mobile" | "office" | "home";
      country_code: string;
      sms?: boolean | null;
      created_at?: string;
      updated_at?: string | null;
      deleted_at?: string | null;
    }[];
    calendly_user_info?: string | null;
    has_password?: boolean | null;
  };
};
export type DeleteUserApiResponse = /** status 204 An empty response */ void;
export type DeleteUserApiArg = {
  /** record id */
  id: number;
};
export type GetUsersApiResponse =
  /** status 200 Multiple user response with orgs and roles */ {
    count: number;
    rows: ({
      id: number;
      email: string;
      normalized_email?: string | null;
      first_name: string;
      last_name: string;
      password?: string | null;
      login_attempts?: number;
      last_login?: string | null;
      last_failed_login?: string | null;
      forgot_password_token?: string | null;
      forgot_password_created_at?: string | null;
      mfa_code?: string | null;
      mfa_attempts?: number;
      mfa_attempted_at?: string | null;
      mfa_required?: boolean | null;
      internal_notes?: string | null;
      title?:
        | ("Mr." | "Mrs." | "Rabbi" | "Ms." | "Dr." | "Rebbetzin" | null)
        | null;
      availability?: string | null;
      calendly_link?: string | null;
      timer_is_active?: boolean | null;
      calendly_user_info?: string | null;
      default_org_id?: number | null;
      timezone?: string;
      created_at?: string;
      updated_at?: string | null;
      deleted_at?: string | null;
    } & {
      orgs: ({
        id: number;
        pipedrive_org_id?: number | null;
        name?: string;
        phone?: string | null;
        country_code?: string | null;
        website?: string;
        formatted_address?: string | null;
        address?: string | null;
        address_line_2?: string | null;
        city?: string | null;
        state?: string | null;
        country?: string | null;
        zip?: string | null;
        it_info?: string | null;
        consultant_id?: number | null;
        sub_consultant_id?: number | null;
        account_manager_id?: number | null;
        internal_notes?: string | null;
        license_status_id?: number | null;
        code?: string;
        seq?: number;
        quickbooks_customer_id?: string | null;
        created_at?: string;
        updated_at?: string | null;
        deleted_at?: string | null;
      } & {
        user_roles: {
          id?: number;
          user_id?: number;
          role_id: number;
          is_primary?: boolean;
          org_id?: number | null;
          position_id?: number | null;
          preference_id?: number | null;
          created_at?: string;
          updated_at?: string | null;
          deleted_at?: string | null;
          position_name?: string | null;
          role_name?: string | null;
        }[];
      })[];
      phones: {
        id?: number;
        user_id?: number;
        phone_number: string;
        extension?: string | null;
        is_primary?: boolean;
        type: "mobile" | "office" | "home";
        country_code: string;
        sms?: boolean | null;
        created_at?: string;
        updated_at?: string | null;
        deleted_at?: string | null;
      }[];
      calendly_user_info?: string | null;
      has_password?: boolean | null;
    })[];
  };
export type GetUsersApiArg = {
  /** order by */
  sort?: string;
  /** filter by */
  field?: string;
  /** filter by */
  filter?: string;
  /** range for query limit */
  range?: number[];
  /** link type */
  linkType?: "and" | "or";
  /** search Query param */
  q?: string;
  /** admire or client user */
  userType?: "admire" | "client";
  /** Get inactive users */
  inactive?: boolean;
};
export type CreateUserApiResponse =
  /** status 204 An empty response */ void | /** status 214 success with warnings */ {
    code: number;
    message: string;
  };
export type CreateUserApiArg = {
  /** Create single user */
  body: {
    id: number;
    email: string;
    normalized_email?: string | null;
    first_name: string;
    last_name: string;
    password?: string | null;
    login_attempts?: number;
    last_login?: string | null;
    last_failed_login?: string | null;
    forgot_password_token?: string | null;
    forgot_password_created_at?: string | null;
    mfa_code?: string | null;
    mfa_attempts?: number;
    mfa_attempted_at?: string | null;
    mfa_required?: boolean | null;
    internal_notes?: string | null;
    title?:
      | ("Mr." | "Mrs." | "Rabbi" | "Ms." | "Dr." | "Rebbetzin" | null)
      | null;
    availability?: string | null;
    calendly_link?: string | null;
    timer_is_active?: boolean | null;
    calendly_user_info?: string | null;
    default_org_id?: number | null;
    timezone?: string;
    created_at?: string;
    updated_at?: string | null;
    deleted_at?: string | null;
  } & {
    orgs: ({
      id: number;
      pipedrive_org_id?: number | null;
      name?: string;
      phone?: string | null;
      country_code?: string | null;
      website?: string;
      formatted_address?: string | null;
      address?: string | null;
      address_line_2?: string | null;
      city?: string | null;
      state?: string | null;
      country?: string | null;
      zip?: string | null;
      it_info?: string | null;
      consultant_id?: number | null;
      sub_consultant_id?: number | null;
      account_manager_id?: number | null;
      internal_notes?: string | null;
      license_status_id?: number | null;
      code?: string;
      seq?: number;
      quickbooks_customer_id?: string | null;
      created_at?: string;
      updated_at?: string | null;
      deleted_at?: string | null;
    } & {
      user_roles: {
        id?: number;
        user_id?: number;
        role_id: number;
        is_primary?: boolean;
        org_id?: number | null;
        position_id?: number | null;
        preference_id?: number | null;
        created_at?: string;
        updated_at?: string | null;
        deleted_at?: string | null;
        position_name?: string | null;
        role_name?: string | null;
      }[];
    })[];
    phones: {
      id?: number;
      user_id?: number;
      phone_number: string;
      extension?: string | null;
      is_primary?: boolean;
      type: "mobile" | "office" | "home";
      country_code: string;
      sms?: boolean | null;
      created_at?: string;
      updated_at?: string | null;
      deleted_at?: string | null;
    }[];
    calendly_user_info?: string | null;
    has_password?: boolean | null;
  };
};
export type GetConsultantsAndAmsApiResponse =
  /** status 200 Consultants and AMs */ {
    consultant: {
      id: number;
      email?: string;
      phone?: string | null;
      first_name: string;
      last_name: string;
    }[];
    account_manager: {
      id: number;
      email?: string;
      phone?: string | null;
      first_name: string;
      last_name: string;
    }[];
  };
export type GetConsultantsAndAmsApiArg = void;
export type GetConsultantsApiResponse = /** status 200 users list response */ {
  data: {
    id: number;
    email?: string;
    normalized_email?: string | null;
    first_name: string;
    last_name: string;
    password?: string | null;
    login_attempts?: number;
    last_login?: string | null;
    last_failed_login?: string | null;
    forgot_password_token?: string | null;
    forgot_password_created_at?: string | null;
    mfa_code?: string | null;
    mfa_attempts?: number;
    mfa_attempted_at?: string | null;
    mfa_required?: boolean | null;
    internal_notes?: string | null;
    title?:
      | ("Mr." | "Mrs." | "Rabbi" | "Ms." | "Dr." | "Rebbetzin" | null)
      | null;
    availability?: string | null;
    calendly_link?: string | null;
    timer_is_active?: boolean | null;
    calendly_user_info?: string | null;
    default_org_id?: number | null;
    timezone?: string;
    created_at?: string;
    updated_at?: string | null;
    deleted_at?: string | null;
  }[];
};
export type GetConsultantsApiArg = void;
export type GetAccountManagersApiResponse =
  /** status 200 users list response */ {
    data: {
      id: number;
      email?: string;
      normalized_email?: string | null;
      first_name: string;
      last_name: string;
      password?: string | null;
      login_attempts?: number;
      last_login?: string | null;
      last_failed_login?: string | null;
      forgot_password_token?: string | null;
      forgot_password_created_at?: string | null;
      mfa_code?: string | null;
      mfa_attempts?: number;
      mfa_attempted_at?: string | null;
      mfa_required?: boolean | null;
      internal_notes?: string | null;
      title?:
        | ("Mr." | "Mrs." | "Rabbi" | "Ms." | "Dr." | "Rebbetzin" | null)
        | null;
      availability?: string | null;
      calendly_link?: string | null;
      timer_is_active?: boolean | null;
      calendly_user_info?: string | null;
      default_org_id?: number | null;
      timezone?: string;
      created_at?: string;
      updated_at?: string | null;
      deleted_at?: string | null;
    }[];
  };
export type GetAccountManagersApiArg = void;
export type GetAdmireUsersListApiResponse =
  /** status 200 users list response */ {
    data: {
      id: number;
      email?: string;
      normalized_email?: string | null;
      first_name: string;
      last_name: string;
      password?: string | null;
      login_attempts?: number;
      last_login?: string | null;
      last_failed_login?: string | null;
      forgot_password_token?: string | null;
      forgot_password_created_at?: string | null;
      mfa_code?: string | null;
      mfa_attempts?: number;
      mfa_attempted_at?: string | null;
      mfa_required?: boolean | null;
      internal_notes?: string | null;
      title?:
        | ("Mr." | "Mrs." | "Rabbi" | "Ms." | "Dr." | "Rebbetzin" | null)
        | null;
      availability?: string | null;
      calendly_link?: string | null;
      timer_is_active?: boolean | null;
      calendly_user_info?: string | null;
      default_org_id?: number | null;
      timezone?: string;
      created_at?: string;
      updated_at?: string | null;
      deleted_at?: string | null;
    }[];
  };
export type GetAdmireUsersListApiArg = void;
export type GetUserProfileApiResponse = /** status 200 Show single user */ {
  id: number;
  email: string;
  normalized_email?: string | null;
  first_name: string;
  last_name: string;
  password?: string | null;
  login_attempts?: number;
  last_login?: string | null;
  last_failed_login?: string | null;
  forgot_password_token?: string | null;
  forgot_password_created_at?: string | null;
  mfa_code?: string | null;
  mfa_attempts?: number;
  mfa_attempted_at?: string | null;
  mfa_required?: boolean | null;
  internal_notes?: string | null;
  title?:
    | ("Mr." | "Mrs." | "Rabbi" | "Ms." | "Dr." | "Rebbetzin" | null)
    | null;
  availability?: string | null;
  calendly_link?: string | null;
  timer_is_active?: boolean | null;
  calendly_user_info?: string | null;
  default_org_id?: number | null;
  timezone?: string;
  created_at?: string;
  updated_at?: string | null;
  deleted_at?: string | null;
} & {
  orgs: ({
    id: number;
    pipedrive_org_id?: number | null;
    name?: string;
    phone?: string | null;
    country_code?: string | null;
    website?: string;
    formatted_address?: string | null;
    address?: string | null;
    address_line_2?: string | null;
    city?: string | null;
    state?: string | null;
    country?: string | null;
    zip?: string | null;
    it_info?: string | null;
    consultant_id?: number | null;
    sub_consultant_id?: number | null;
    account_manager_id?: number | null;
    internal_notes?: string | null;
    license_status_id?: number | null;
    code?: string;
    seq?: number;
    quickbooks_customer_id?: string | null;
    created_at?: string;
    updated_at?: string | null;
    deleted_at?: string | null;
  } & {
    user_roles: {
      id?: number;
      user_id?: number;
      role_id: number;
      is_primary?: boolean;
      org_id?: number | null;
      position_id?: number | null;
      preference_id?: number | null;
      created_at?: string;
      updated_at?: string | null;
      deleted_at?: string | null;
      position_name?: string | null;
      role_name?: string | null;
    }[];
  })[];
  phones: {
    id?: number;
    user_id?: number;
    phone_number: string;
    extension?: string | null;
    is_primary?: boolean;
    type: "mobile" | "office" | "home";
    country_code: string;
    sms?: boolean | null;
    created_at?: string;
    updated_at?: string | null;
    deleted_at?: string | null;
  }[];
  calendly_user_info?: string | null;
  has_password?: boolean | null;
};
export type GetUserProfileApiArg = void;
export type UpdateUserProfileApiResponse =
  /** status 204 An empty response */ void;
export type UpdateUserProfileApiArg = {
  /** user */
  body: {
    id?: number;
    email: string;
    first_name: string;
    last_name: string;
    mfa_required?: boolean | null;
    is_primary?: boolean | null;
    title?:
      | ("Mr." | "Mrs." | "Ms." | "Dr." | "Rabbi" | "Rebbetzin" | null)
      | null;
    availability?: string | null;
    internal_notes?: string | null;
    phones: {
      id?: number;
      user_id?: number;
      phone_number: string;
      country_code: string;
      type: "mobile" | "home" | "office";
      extension?: string | null;
      sms?: boolean | null;
      is_primary?: boolean | null;
      created_at?: string | null;
      updated_at?: string | null;
      deleted_at?: string | null;
    }[];
    calendly_link?: string | null;
    default_org_id?: number | null;
    userRoles: {
      org_id: number;
      position_id?: number | null;
      roles: number[];
    }[];
  };
};
export type GetUserActivityApiResponse = /** status 200 activities response */ {
  activities: ({
    id: number;
    type: string;
    title: string;
    entity: string;
    entity_id: number;
    created_by: number;
    value?: string | null;
    old_value?: string | null;
    created_at: string;
  } & {
    created_by_user: {
      id: number;
      first_name: string;
      last_name: string;
    };
  })[];
};
export type GetUserActivityApiArg = {
  /** record id */
  id: number;
};
export type ImpersonateApiResponse = /** status 200 jwt permissions token */ {
  token: string;
  refreshToken?: string;
  expiration?: string;
  user?: {
    id: number;
    email: string;
    normalized_email?: string | null;
    first_name: string;
    last_name: string;
    password?: string | null;
    login_attempts?: number;
    last_login?: string | null;
    last_failed_login?: string | null;
    forgot_password_token?: string | null;
    forgot_password_created_at?: string | null;
    mfa_code?: string | null;
    mfa_attempts?: number;
    mfa_attempted_at?: string | null;
    mfa_required?: boolean | null;
    internal_notes?: string | null;
    title?:
      | ("Mr." | "Mrs." | "Rabbi" | "Ms." | "Dr." | "Rebbetzin" | null)
      | null;
    availability?: string | null;
    calendly_link?: string | null;
    timer_is_active?: boolean | null;
    calendly_user_info?: string | null;
    default_org_id?: number | null;
    timezone?: string;
    created_at?: string;
    updated_at?: string | null;
    deleted_at?: string | null;
  } & {
    orgs: ({
      id: number;
      pipedrive_org_id?: number | null;
      name?: string;
      phone?: string | null;
      country_code?: string | null;
      website?: string;
      formatted_address?: string | null;
      address?: string | null;
      address_line_2?: string | null;
      city?: string | null;
      state?: string | null;
      country?: string | null;
      zip?: string | null;
      it_info?: string | null;
      consultant_id?: number | null;
      sub_consultant_id?: number | null;
      account_manager_id?: number | null;
      internal_notes?: string | null;
      license_status_id?: number | null;
      code?: string;
      seq?: number;
      quickbooks_customer_id?: string | null;
      created_at?: string;
      updated_at?: string | null;
      deleted_at?: string | null;
    } & {
      user_roles: {
        id?: number;
        user_id?: number;
        role_id: number;
        is_primary?: boolean;
        org_id?: number | null;
        position_id?: number | null;
        preference_id?: number | null;
        created_at?: string;
        updated_at?: string | null;
        deleted_at?: string | null;
        position_name?: string | null;
        role_name?: string | null;
      }[];
    })[];
    phones: {
      id?: number;
      user_id?: number;
      phone_number: string;
      extension?: string | null;
      is_primary?: boolean;
      type: "mobile" | "office" | "home";
      country_code: string;
      sms?: boolean | null;
      created_at?: string;
      updated_at?: string | null;
      deleted_at?: string | null;
    }[];
    calendly_user_info?: string | null;
    has_password?: boolean | null;
  };
};
export type ImpersonateApiArg = {
  /** record id */
  id: number;
};
export type TurnOffUserTimerApiResponse =
  /** status 204 An empty response */ void;
export type TurnOffUserTimerApiArg = {
  /** record id */
  id: number;
  /** deactivate timer for a given user */
  deactivateTimer?: boolean;
};
export const {
  useAuthLoginMutation,
  useAuthRefreshTokenQuery,
  useLazyAuthRefreshTokenQuery,
  useAuthLogoutQuery,
  useLazyAuthLogoutQuery,
  useAuthSocialLoginMutation,
  useAuthForgotPasswordMutation,
  useAuthInviteEmailMutation,
  useAuthResetPasswordMutation,
  useAuthMultiFactorAuthMutation,
  useAuthResendMfaCodeQuery,
  useLazyAuthResendMfaCodeQuery,
  useGetAdmireUsersQuery,
  useLazyGetAdmireUsersQuery,
  useManageAssigneesMutation,
  useGetUserQuery,
  useLazyGetUserQuery,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useGetUsersQuery,
  useLazyGetUsersQuery,
  useCreateUserMutation,
  useGetConsultantsAndAmsQuery,
  useLazyGetConsultantsAndAmsQuery,
  useGetConsultantsQuery,
  useLazyGetConsultantsQuery,
  useGetAccountManagersQuery,
  useLazyGetAccountManagersQuery,
  useGetAdmireUsersListQuery,
  useLazyGetAdmireUsersListQuery,
  useGetUserProfileQuery,
  useLazyGetUserProfileQuery,
  useUpdateUserProfileMutation,
  useGetUserActivityQuery,
  useLazyGetUserActivityQuery,
  useImpersonateMutation,
  useTurnOffUserTimerMutation,
} = injectedRtkApi;
