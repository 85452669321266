import { Button, Link, Stack, Typography, useTheme } from "@mui/material";
import { formatDate } from "../../lib";
import {
  ClockIcon,
  AvatarGroup,
  CardStyled,
  CardContentContainerStyled,
  CardContentItemStyled,
  Modal,
  AddTaskButton,
  ScheduleSession,
  AttendeeForm,
  JoinZoomMeetingButton,
  useCurOrg,
} from "../../components";
import { memo, useCallback, useState, useMemo } from "react";
import { useMobile } from "../../themes";
import { GetSessionsByOrgIdApiResponse } from "../../state/rtk-query/state/session";
import { ArrayElement } from "../../global";
import { Link as RouterLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { authSelectors } from "../../state";
import { SessionTimeRange } from "../misc";
type SessionFromOrg = ArrayElement<GetSessionsByOrgIdApiResponse["rows"]>;
interface Props {
  session: SessionFromOrg;
  displayTodays?: boolean;
}
/* Maybe don't show the card at all if its missing these */
export const MiniSessionCard = memo(
  ({ session, displayTodays = true }: Props) => {
    const { palette } = useTheme();
    const isMobile = useMobile();
    const {
      booking,
      session_invitees,
      id,
      title,
      org_id,
      pending_session_dates,
    } = session;
    const isInternal = useSelector(authSelectors.isInternal);
    const org = useCurOrg(org_id);

    const { start_time, end_time } = booking ?? {};

    const [openSessions, setOpenSessions] = useState(false);
    const [openAttendeeModal, setOpenAttendeeModal] = useState(false);
    const openSessionsModal = useCallback(() => {
      setOpenSessions(!openSessions);
    }, [openSessions]);

    const closeAttendeeModal = useCallback(
      () => setOpenAttendeeModal(false),
      [openAttendeeModal],
    );
    const clientAssignee =
      session?.client_assignee_user ?? session?.created_by_user;
    const users = useMemo(
      () => [
        { ...session.assigned_to_user, label: "Admire assignee" },
        { ...clientAssignee, label: "Client assignee" },
        ...(session_invitees || [])
          .map((u) =>
            u.user?.length ? u.user : { first_name: "", last_name: u.email },
          )
          .flat()
          .sort((a: any, b: any) => +!!b?.id - +!!a?.id),
      ],
      [session.assigned_to_user, clientAssignee, session_invitees],
    );

    const sessionTitle = booking?.start_time
      ? formatDate(new Date(booking.start_time), "EEEE, LLLL do")
      : pending_session_dates?.[0]?.value
      ? formatDate(new Date(pending_session_dates[0].value), "LLLL")
      : "Unscheduled Session";

    return (
      <>
        <CardStyled
          sx={{
            height: isMobile ? "100%" : "auto",
            width: "325px",
            minWidth: "325px",
            "&:hover": {
              border: `1px solid ${palette.primary[300]}`,
              boxShadow: `0 1px 4px rgba(85,95,230,0.5)`,
            },
          }}
        >
          <CardContentContainerStyled container height={"100%"}>
            <Link
              to={`/sessions/${session.id}`}
              component={RouterLink}
              sx={{
                fontSize: "16px",
                fontWeight: 600,
              }}
              color={palette.grey[900]}
              width={"100%"}
            >
              <CardContentItemStyled
                item
                display={"flex"}
                flexDirection={"column"}
                p={isMobile ? 1 : 2}
                sx={{ width: "100% !important" }}
                minWidth={"100%"}
                gap={1}
              >
                <Stack
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  width={"100%"}
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: 600,
                      width: "100%",
                    }}
                    color={palette.grey[900]}
                    noWrap
                    textOverflow={"ellipsis"}
                  >
                    {displayTodays ? org?.name || sessionTitle : sessionTitle}
                  </Typography>
                  <AvatarGroup
                    users={users}
                    dimensions={24}
                    maxBeforeGrouping={2}
                    defaultLabel="Invitee"
                    labelForRemaining="Invitees"
                    setOpenModal={isInternal ? setOpenAttendeeModal : undefined}
                    spacing={-0.5}
                  />
                </Stack>
                <Stack direction={"row"} alignItems={"center"} mt={0.5}>
                  <Stack display={"flex"} alignSelf={"flex-start"}>
                    <ClockIcon fontSize="small" color={palette.primary.main} />
                  </Stack>
                  <Typography
                    pl={1}
                    fontSize={14}
                    variant="subtitle2"
                    maxWidth={start_time ? 140 : "100%"}
                    textOverflow={"ellipsis"}
                    noWrap
                  >
                    {title?.trim() || "Consulting Session"}
                  </Typography>
                  {start_time ? (
                    <Typography fontSize={14} variant="subtitle2">
                      :&nbsp;
                      <SessionTimeRange
                        startTime={start_time}
                        endTime={end_time}
                      />
                    </Typography>
                  ) : (
                    <Button
                      onClick={openSessionsModal}
                      sx={{ padding: 0, height: "min-content" }}
                    >
                      Schedule
                    </Button>
                  )}
                </Stack>
                <AddTaskButton session={session} />
                <JoinZoomMeetingButton booking={booking} miniCard />
              </CardContentItemStyled>
            </Link>
          </CardContentContainerStyled>
        </CardStyled>

        <Modal open={openSessions} onClose={openSessionsModal}>
          <ScheduleSession sessionId={id} />
        </Modal>
        <AttendeeForm
          open={openAttendeeModal}
          onClose={closeAttendeeModal}
          session={session}
        />
      </>
    );
  },
);
