import {
  Stack,
  Typography,
  Button,
  useTheme,
  IconButton,
  Divider,
  Link,
  TextField,
} from "@mui/material";
import { formatDate, formatDateToHuman } from "../../lib";
import {
  ExpandArrowsIcon,
  CloseExpandedArrowsIcon,
  ClockIcon,
  CloseIcon,
} from "../icons";
import { ArrayElement } from "../../global";
import {
  GetSessionsByOrgIdApiResponse,
  useCancelSessionMutation,
} from "../../state/rtk-query/state/session";
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useState,
} from "react";
import {
  StatusChip,
  CardStyled,
  AddTaskButton,
  TaskCard,
  CreateSessionForm,
  Modal,
  ConfirmDeleteModal,
  AttendeeForm,
  SessionMenu,
  JoinZoomMeetingButton,
} from "..";
import { useMobile } from "../../themes";
import { useSelector } from "react-redux";
import { authSelectors } from "../../state";
import { isFuture } from "date-fns";
import { useOrgId } from "../hooks";
import { Link as RouterLink } from "react-router-dom";
import { SessionActivityButton } from "../buttons/SessionActivityButton";
import { SessionAvatarGroup } from "../icons/SessionAvatarGroup";
import { useStatusesList } from "../hooks/useLists";
import { SessionTimeRange } from "../misc";

type SessionType = ArrayElement<GetSessionsByOrgIdApiResponse["rows"]>;

interface SessionCardProp {
  session: SessionType;
  expand: number[];
  setExpandAll: Dispatch<SetStateAction<number[]>>;
  viewer?: "internal" | "external";
}
const PADDING_LEFT = 18;
const PADDING_Y = 18;

export const SessionCard = ({
  session,
  expand = [],
  setExpandAll,
  viewer = "external",
}: SessionCardProp) => {
  const isMobile = useMobile();
  const { palette } = useTheme();
  const [openSessionModal, setOpenSessionModal] = useState(false);
  const [openAttendeeModal, setOpenAttendeeModal] = useState(false);
  const [cancelReason, setCancelReason] = useState("");
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const orgId = useOrgId();
  const {
    id,
    tasks,
    booking,
    title,
    session_invitees,
    status_id,
    org_id,
    pending_session_dates: psd,
  } = session;
  const statuses = useStatusesList("session");
  const bookingStatus = statuses.getStatus(status_id);
  const isInternal = useSelector(authSelectors.isInternal);

  const [cancelSession] = useCancelSessionMutation();
  const isExpanded = expand.includes(Number(id)) && Number(tasks?.length) > 0;
  const isNSCancelRescheduled = bookingStatus?.transition_phase === "blocked";

  const cancelId = statuses.getStatusByName("canceled");

  const onCloseSessionModal = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();
      setOpenSessionModal(() => !openSessionModal);
    },
    [openSessionModal],
  );
  const recordingClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    window.open(`${booking?.play_url}?pwd=${booking?.meeting_password}`);
  };

  const handleCancel = useCallback(async () => {
    if (id && orgId) {
      await cancelSession({
        id,
        orgId,
        body: { reason: cancelReason },
      });
    }

    setOpenCancelModal(false);
  }, [id, cancelReason, cancelId]);

  const expandSession = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      e.preventDefault();
      if (isExpanded) {
        setExpandAll(() => expand.filter((cur) => cur !== id) ?? []);
      } else {
        setExpandAll((old) => [...old, Number(id)]);
      }
    },
    [id, isExpanded, expand],
  );
  const setReason = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setCancelReason(e?.target.value);
    },
    [id, cancelReason],
  );

  const openCancelModalClick = useCallback(() => {
    setOpenCancelModal(!openCancelModal);
  }, [openCancelModal]);

  const openAttendeeModalClick = useCallback(
    () => setOpenAttendeeModal(true),
    [openAttendeeModal],
  );
  const closeAttendeeModal = useCallback(
    () => setOpenAttendeeModal(false),
    [openAttendeeModal],
  );

  const defaultTitle = (
    <>
      {" "}
      {booking?.start_time
        ? formatDateToHuman(new Date(booking?.start_time ?? ""))
        : psd
        ? `Pending: ${formatDate(psd[0]?.value ?? "")} -  ${formatDate(
            psd[1]?.value ?? "",
          )}`
        : ""}{" "}
      {booking?.start_time || psd ? <> &middot;</> : null}{" "}
      {title || "Consulting Session"}
    </>
  );
  return (
    <>
      <CardStyled
        sx={{
          pl: `${PADDING_LEFT}px`,
          py: `${PADDING_Y}px`,
          background: isNSCancelRescheduled
            ? `transparent`
            : isExpanded
            ? palette.grey[200]
            : "",
          borderBottomLeftRadius: isExpanded ? "0" : "",
          borderBottomRightRadius: isExpanded ? "0" : "",
          borderWidth: isExpanded ? "2px" : "1px",
        }}
      >
        <Link
          to={`/sessions/${session.id}`}
          component={RouterLink}
          sx={{ fontSize: "18px", fontWeight: 600 }}
          color={isNSCancelRescheduled ? palette.grey[800] : palette.grey[900]}
        >
          <Stack
            direction="column"
            //height={`calc(96px - ${PADDING_Y}px)`}
            height={"auto"}
            justifyContent={"space-between"}
            gap={isMobile ? 2 : 0}
          >
            <Stack
              direction={isMobile ? "column-reverse" : "row"}
              justifyContent={"space-between"}
              gap={isMobile ? 2 : 0}
              alignItems={isMobile ? undefined : "center"}
            >
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: 600,
                  width: "100%",
                }}
                color={palette.grey[900]}
              >
                {defaultTitle}
              </Typography>
              <Stack direction="row" alignItems={"center"} gap={1} mr={2}>
                {!!booking?.duration && !isMobile && (
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    whiteSpace={"nowrap"}
                    gap={0.5}
                  >
                    <ClockIcon color={palette.secondary[300]} size={16} />
                    <Typography
                      color={palette.secondary[600]}
                      fontWeight={600}
                      fontSize={12}
                    >
                      {booking.duration} min
                    </Typography>
                  </Stack>
                )}
                <Stack
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <StatusChip
                    statusId={status_id}
                    type="session"
                    allowNoShow={!isFuture(new Date(booking?.start_time ?? ""))}
                    dropDown
                    id={id}
                    orgId={orgId}
                    sx={{
                      marginRight: session_invitees?.length ? 2 : 0,
                    }}
                  />
                </Stack>
                {isMobile && <Stack flexGrow={1} />}
                <SessionAvatarGroup
                  session={session}
                  viewer={viewer}
                  setOpenAttendeeModal={openAttendeeModalClick}
                />
                {Number(tasks?.length) > 0 && !isMobile && (
                  <IconButton onClick={expandSession}>
                    {!isExpanded ? (
                      <ExpandArrowsIcon style={{ width: 24, height: 24 }} />
                    ) : (
                      <CloseExpandedArrowsIcon
                        style={{ width: 24, height: 24 }}
                      />
                    )}
                  </IconButton>
                )}

                {session && Number(id) ? (
                  <SessionMenu
                    status={bookingStatus?.internal_name ?? ""}
                    session={session}
                    id={Number(id)}
                  />
                ) : null}
              </Stack>
            </Stack>
            <Stack direction={"row"} justifyContent={"space-between"}>
              <Stack
                direction={isMobile ? "column" : "row"}
                alignItems={isMobile ? "flex-start" : "center"}
                width={isMobile ? "100%" : "40%"}
                justifyContent={"space-between"}
                gap={isMobile ? 2 : 0}
              >
                <Stack
                  direction={"row"}
                  alignItems={isMobile && booking ? "flex-start" : "center"}
                >
                  <ClockIcon
                    color={palette.primary.main}
                    width={18}
                    height={18}
                  />
                  {booking ? (
                    <>
                      <Typography
                        variant="subtitle2"
                        fontSize={14}
                        ml={1}
                        noWrap={!isMobile}
                      >
                        {isMobile ? (
                          formatDate(
                            new Date(booking?.start_time ?? ""),
                            "EEE, MMM do",
                          )
                        ) : (
                          <SessionTimeRange
                            startTime={booking?.start_time}
                            endTime={booking?.end_time}
                          />
                        )}
                      </Typography>
                      {booking?.play_url ? (
                        <Button sx={{ pl: 2, pr: 0 }} onClick={recordingClick}>
                          Recording
                        </Button>
                      ) : (
                        <JoinZoomMeetingButton booking={booking} />
                      )}
                    </>
                  ) : !booking ? (
                    <>
                      <Button
                        disabled={Boolean(session?.deleted_at)}
                        onClick={onCloseSessionModal}
                      >
                        Schedule a session
                      </Button>
                      <Modal
                        open={openSessionModal}
                        onClose={onCloseSessionModal}
                        sx={{
                          width: isMobile ? "100%" : "60%",
                          height: "max-content",
                        }}
                      >
                        <Stack
                          direction={"row"}
                          width={"100%"}
                          justifyContent={"flex-end"}
                        >
                          <IconButton onClick={onCloseSessionModal}>
                            <CloseIcon />
                          </IconButton>
                        </Stack>

                        <CreateSessionForm
                          removeHeader
                          session_id={id}
                          orgId={isInternal ? org_id : orgId}
                        />
                      </Modal>
                    </>
                  ) : null}
                </Stack>
                <AddTaskButton
                  session={session}
                  disabled={isNSCancelRescheduled}
                />
              </Stack>
              {!isMobile && (
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"flex-end"}
                  pr={"18px"}
                >
                  {!!session?.created_at && (
                    <Typography
                      fontSize={14}
                      fontWeight={500}
                      color={palette.grey[700]}
                    >{`Created on ${formatDate(
                      session.created_at,
                    )}`}</Typography>
                  )}
                  <SessionActivityButton id={id} />
                </Stack>
              )}
            </Stack>
          </Stack>
        </Link>
      </CardStyled>

      {isExpanded && tasks?.length && !isMobile ? (
        <Stack
          sx={{
            background: "white",
            px: `${PADDING_LEFT}px`,
          }}
          width={"100%"}
          direction={"column"}
          divider={<Divider />}
          border={`2px solid ${palette.grey[500]}`}
          borderRadius={"0 0 6px 6px "}
          borderTop={0}
        >
          {tasks?.map((t) => {
            return (
              <Stack
                key={t?.id}
                sx={{
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
              >
                <TaskCard task={t} key={t.id} hideMenuButtons />
              </Stack>
            );
          })}
        </Stack>
      ) : null}
      <ConfirmDeleteModal
        open={openCancelModal}
        onClose={openCancelModalClick}
        handleConfirm={handleCancel}
        titleText="Are you sure you want to cancel this session?"
        confirmActionButtonText="Confirm"
        skipActionButtonText="Do not cancel"
      >
        {" "}
        {/* TODO: find out if this is required */}
        <Stack mt={2}>
          <Typography fontSize={14} lineHeight={0} my={2}>
            Reason for cancellation
          </Typography>

          <TextField onChange={setReason} value={cancelReason} />
        </Stack>
      </ConfirmDeleteModal>
      <AttendeeForm
        open={openAttendeeModal && isInternal}
        onClose={closeAttendeeModal}
        session={session}
      />
    </>
  );
};
